import { useContext, useEffect, useState } from "react";
import validator from "validator";
import AdoDataContext from "../../context/AdoDataContext";
import { useOrganizationContext } from "../../context/OrganizationContext";
import successIcon from '../../assets/success.svg';

interface SurveyModalProps {
    isOpen: boolean;
    onClose: () => void;
    surveyEmail: string;
	surveyBusiness: string;
	surveyBusinessLegal: string;
	surveyUbi: string;
	surveyName: string;
	initiativeId: number | null;
	interactionId: number | null;
}

function SurveyModal({ isOpen, onClose, surveyEmail, surveyBusiness, surveyBusinessLegal, surveyUbi, surveyName, initiativeId, interactionId }: SurveyModalProps) {
	// Access partner data from context, which provides details on the organization
	const { partnerData } = useContext(AdoDataContext);
	const { partnerId } = useOrganizationContext();

	// Local state to manage user inputs (email, reply-to email, business info)
	const [email, setEmail] = useState("");
	const [replyToEmail, setReplyToEmail] = useState("");
	const [business, setBusiness] = useState("");
	const [ubi, setUbi] = useState("");
	const [firstName, setFirstName] = useState("");
	const [isSubmitted, setIsSubmitted] = useState(false);

	const formData = {
		method: 'email',
		status: 'sent',
		activated_at: new Date().toISOString(),
		recipient_email: email,
		survey_link: null,
		completed_at: null,
		collected_by: null,
		notes: `Survey emailed to ${firstName} from application.`,
	};

	// Find the partner associated with the partnerId, used to pre-populate reply-to email and short name if available
	const partner = partnerData.find(partner => partner.id === partnerId);
	const partnerEmail = partner?.partner_email_address;
	const partnerName = partner?.name;
	const partnerShortName = partner?.short_name;

    useEffect(() => {
		// Initialize modal fields with the values passed in via props (survey data)
        setEmail(surveyEmail);
		
		// Set business name if it exists, otherwise fall back to business legal name
		if (surveyBusiness) {
			setBusiness(surveyBusiness);
		} else if (surveyBusinessLegal) {
			setBusiness(surveyBusinessLegal);
		}

		setUbi(surveyUbi);

		// Prepopulate reply-to email with partner email if it exists
		if (partnerEmail) {
			setReplyToEmail(partnerEmail);
		}

		// Set first name if it exists
		if (surveyName) {
			setFirstName(surveyName);
		}

    }, [surveyEmail, surveyBusiness, surveyBusinessLegal, surveyUbi, partnerEmail])

    if (!isOpen) return null;

	// Function to validate email addresses using an external validator
    const validateEmail = (email: string) => {
      return validator.isEmail(email);
    }

	const validateFormData = () => {
		const allowedStatuses = ["pending", "sent", "completed", "failed"];
		const allowedMethods = ["email", "phone", "pre-collected", "in-person", "online portal"];
	
		// Validate and normalize status
		if (!allowedStatuses.includes(formData.status)) {
		  formData.status = 'pending';
		}
	
		// Validate and normalize method
		if (!allowedMethods.includes(formData.method)) {
		  formData.method = 'email';
		}
	};

	// Handle submission of the survey modal form
    const handleSubmit = async () => {
		// Prevent form submission if either email or reply-to email is invalid
		if (!validateEmail(email) || !validateEmail(replyToEmail) || firstName.trim() === "") {
			console.log('form is invalid...');
			return;
		}
    
        try {
			// Validate survey data
			validateFormData();
	  
			// First API call: Send email data
			const emailPayload = {
			  email,
			  replyToEmail,
			  business,
			  ubi,
			  partnerShortName,
			  partnerName,
			  firstName,
			};
	  
			const emailUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/sendsurvey`;
			const emailResponse = await fetch(emailUrl, {
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/json',
			  },
			  body: JSON.stringify(emailPayload),
			});
	  
			if (!emailResponse.ok) {
			  throw new Error('Failed to send email.');
			}

			// Extract survey link from the email response
			const emailData = await emailResponse.json();
			const surveyLink = emailData.survey_link;

			if (!surveyLink) {
				throw new Error('Survey link not returned from email response.');
			}
	  
			console.log(`Email successfully sent to ${email}. Survey link: ${surveyLink}`);

			// Update formData with the survey link
			formData.survey_link = surveyLink;
	  
			// Second API call: Send survey data
			const surveyPayload = {
			  formData,
			  initiativeId,
			  interactionId
			};
	  
			const surveyUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/addsurvey`;
			const surveyResponse = await fetch(surveyUrl, {
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/json',
			  },
			  body: JSON.stringify(surveyPayload),
			});
	  
			if (!surveyResponse.ok) {
			  throw new Error('Failed to send survey data.');
			}
	  
			console.log('Survey data successfully sent.');
			setIsSubmitted(true); // Set submission status to true on success
		} catch (error) {
			console.error('Error:', error);
		}
    };
    
	// Event handlers for input change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleChangeReplyToEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		setReplyToEmail(e.target.value);
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFirstName(e.target.value);
	};

    return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      	<div className="relative w-auto max-w-lg min-w-80 sm:min-w-[500px] mx-auto my-6 border border-solid border-color-cai-lightGray rounded-lg shadow-lg">
			{/* Content */}
			<div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
				<button
					className="absolute top-2 right-2 text-gray-500 text-lg hover:text-gray-700"
					onClick={onClose}
					>
					&times;
				</button>
				<div className="p-4 min-h-32 flex flex-col items-left">
					{isSubmitted ? (
						<div className="flex items-center justify-center mt-8">
							<img src={successIcon} alt="success icon" className=" mr-2" />
							<p className="text-green-600 text-md">Survey sent successfully!</p>
						</div>
						) : (
						<>
						<p className="text-color-cai-dashboard text-md mt-2">Recipient Email</p>
						<div className="flex items-center my-2">
							<div className="flex flex-col w-full">
								<input
									type="email"
									id="surveyEmail"
									value={email}
									onChange={handleChange}
									required
									className="pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
								/>
								{!validateEmail(email) && <p className="text-red-500 text-sm">Enter a valid email address.</p>}  
							</div>
						</div>

						{/* New name input field */}
						<p className="text-color-cai-dashboard text-md mt-2">Recipient Name</p>
						<div className="flex items-center my-2">
							<div className="flex flex-col w-full">
								<input
								type="text"
								id="surveyName"
								value={firstName}
								onChange={handleNameChange}
								className="pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
								/>
								{!firstName && <p className="text-red-500 text-sm">Recipient name is required.</p>}
							</div>
						</div>
						<p className="text-color-cai-dashboard text-md mt-2">Reply-To Email</p>
						<div className="flex items-center my-2">
							<div className="flex flex-col w-full">
								<input
									type="email"
									id="replyToEmail"
									value={replyToEmail}
									onChange={handleChangeReplyToEmail}
									required
									className="pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
								/>
								{!validateEmail(replyToEmail) && <p className="text-red-500 text-sm">Enter a valid reply-to email address.</p>}
							</div>
						</div>
						</>
					)}
				</div>

				<div className="flex items-center justify-end p-2 border-t border-solid rounded-b border-blueGray-200">
					{!isSubmitted && (
					<button
						className='m-1 w-36 h-8 text-center text-white bg-color-cai-dashboard border border-solid border-color-cai-dashboard rounded-[4px] focus:outline-none hover:bg-blue-400 hover:border-blue-600 cursor-pointer'
						type="button"
						onClick={handleSubmit}
						>
						Send
					</button>
					)}
					<button
						className="m-1 w-36 h-8 text-center text-color-cai-dashboard bg-white border border-solid border-color-cai-dashboard rounded-[4px] focus:outline-none focus:border-red-400 hover:bg-red-400 hover:border-red-500 hover:text-white cursor-pointer"
						type="button"
						onClick={onClose}
						>
						Close
					</button>
				</div>
			</div>
		</div>
    </div>
  );
};

export default SurveyModal;