import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { AdminProviderProps, TargetsData, TargetsDataContextType } from '../services/types';
import * as Sentry from '@sentry/react';

// Configure axios to retry failed requests
axiosRetry(axios, {
    retries: 3, // Number of retry attempts
    retryDelay: axiosRetry.exponentialDelay, // Exponential backoff delay
    retryCondition: (error) => {
        return axiosRetry.isNetworkOrIdempotentRequestError(error); // Retry on network or 5xx server errors
    },
});

const TargetsDataContext = createContext<TargetsDataContextType>({
    targets: [],
    fetchTargets: () => {},
});

export const TargetsDataProvider = ({ children }: AdminProviderProps) => {
    const [targets, setTargets] = useState<TargetsData[]>([]);
    const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/gettargets`;

    const fetchTargets = async () => {
        try {
            const response = await axios.get(apiUrl);
            if (response.status === 200) {
                const data = response.data.data;
                setTargets(data);
            } else {
                // Log the error response to Sentry
                Sentry.captureMessage(`Error fetching Targets: ${response.data.message}`, 'warning');
                console.error('Error fetching Targets:', response.data);
            }
        } catch (error) {
            // Specific error handling for Axios network errors
            if (axios.isAxiosError(error)) {
                // Check for network error or timeout
                if (error.code === 'ECONNABORTED') {
                    Sentry.captureMessage('Request Timeout: The request took too long to complete', 'warning');
                } else if (error.message.includes('Network Error')) {
                    Sentry.captureMessage('Network Error: Unable to reach the server', 'error');
                }

                // Log additional details like the URL and request payload
                Sentry.withScope(scope => {
                    scope.setContext('Axios Request', { url: apiUrl });
                    scope.setTag('error_code', error.code || 'UNKNOWN');
                    Sentry.captureException(error);
                });

                console.error('Error fetching outcomes:', error);
            } else {
                // Handle non-Axios errors (e.g., JavaScript errors)
                Sentry.captureException(error);
                console.error('Unexpected error fetching outcomes:', error);
            }
        }
    };

    useEffect(() => {
        fetchTargets();
    }, []);

    return (
        <TargetsDataContext.Provider value={{ targets, fetchTargets }}>
            {children}
        </TargetsDataContext.Provider>
    );
};

export default TargetsDataContext;