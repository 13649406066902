import { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { PartnerData } from '../services/types';
import * as Sentry from '@sentry/react';

// Configure axios to retry failed requests
axiosRetry(axios, {
    retries: 3, // Number of retry attempts
    retryDelay: axiosRetry.exponentialDelay, // Exponential backoff delay
    retryCondition: (error) => {
        return axiosRetry.isNetworkOrIdempotentRequestError(error); // Retry on network or 5xx server errors
    },
});

interface AdoDataContextType {
    partnerData: PartnerData[];
    adoNames: { value: string, label: string }[];
    fetchAdoNames: () => void;
}


const AdoDataContext = createContext<AdoDataContextType>({
    partnerData: [],
    adoNames: [],
    fetchAdoNames: () => {},
});

interface AdminProviderProps {
    children: ReactNode;
}

export const AdoDataProvider = ({ children }: AdminProviderProps) => {
    const [partnerData, setPartnerData] = useState<PartnerData[]>([]);
    const [adoNames, setAdoNames] = useState<{ value: string, label: string }[]>([]);
    const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getpartners`;

    const fetchAdoNames = async () => {
        try {
            const response = await axios.get(apiUrl);
            if (response.status === 200) {
                const data: PartnerData[] = response.data.data;
                const names = Array.from(new Set(data.map((item: { name: string }) => item.name.trim())));
                names.sort();
                const options = names.map(name => ({ value: name, label: name }));
                
                setPartnerData(data);
                setAdoNames(options);
            } else {
                console.error('Error fetching ADO names:', response.data);
            }
        } catch (error) {
            // Specific error handling for Axios network errors
            if (axios.isAxiosError(error)) {
                // Check for network error or timeout
                if (error.code === 'ECONNABORTED') {
                    Sentry.captureMessage('Request Timeout: The request took too long to complete', 'warning');
                } else if (error.message.includes('Network Error')) {
                    Sentry.captureMessage('Network Error: Unable to reach the server', 'error');
                }

                // Log additional details like the URL and request payload
                Sentry.withScope(scope => {
                    scope.setContext('Axios Request', { url: apiUrl });
                    scope.setTag('error_code', error.code || 'UNKNOWN');
                    Sentry.captureException(error);
                });

                console.error('Error fetching outcomes:', error);
            } else {
                // Handle non-Axios errors (e.g., JavaScript errors)
                Sentry.captureException(error);
                console.error('Unexpected error fetching outcomes:', error);
            }
        }
    };

    useEffect(() => {
        fetchAdoNames();
    }, []);

    return (
        <AdoDataContext.Provider value={{ partnerData, adoNames, fetchAdoNames }}>
            {children}
        </AdoDataContext.Provider>
    );
};

export default AdoDataContext;
