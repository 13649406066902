import downloadSvg from '../../assets/download-file-icon.svg';
import { InteractionData } from "../../services/types";
import { getCurrentDate } from "../../utils/helpers";
import { useAdminContext } from '../../context/AdminContext';
import XLSX from 'xlsx-js-style';

interface InteractionDownloadProps {
    interactionData: InteractionData[];
}

// InteractionDownload - Handles excel workbook creation, transformations, and Download UI for main interactions page
function InteractionDownload({ interactionData }: InteractionDownloadProps) {
    const { isAdmin } = useAdminContext();
    const currentDate = getCurrentDate();

    // removeStubs - Function that ensures that all cells, even empty ones, are preserved when the worksheet is generated
    const removeStubs = (wb: XLSX.WorkBook) => {
        Object.values(wb.Sheets).forEach((ws: XLSX.WorkSheet) => {
          Object.values(ws).filter((v: XLSX.CellObject) => v.t === 'z').forEach((v: XLSX.CellObject) => Object.assign(v,{t:'s',v:''}));
        });
        return wb;
    }

    const handleDetailDownload = () => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Create interaction sheet if not in admin view
        if (!isAdmin) {
            // Convert interaction data to XLSX
            const interactionWorksheet = XLSX.utils.json_to_sheet(interactionDetails);
            XLSX.utils.sheet_add_aoa(interactionWorksheet, [interactionHeaders.map(header => header.label)], { origin: 'A1' });

            // Remove stubs so we can style empty cells
            removeStubs({
                SheetNames: ['Interactions'],
                Sheets: { 'Interactions': interactionWorksheet } 
            });

            // Set up styles for interaction worksheet (similar to your current code)
            // @ts-ignore
            const interactionRange = XLSX.utils.decode_range(interactionWorksheet['!ref']);

            // Define column widths
            interactionWorksheet['!cols'] = [
                { wch: 17 },
                { wch: 21 },
                { wch: 31 },
                { wch: 31 },
                { wch: 25 },
                { wch: 25 },
                { wch: 31 },
                { wch: 25 },
                { wch: 27 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 25 },
                { wch: 21 },
                { wch: 21 },
                { wch: 17 },
                { wch: 17 },
                { wch: 20 },
            ];

            for (let row = interactionRange.s.r; row <= interactionRange.e.r; row++) {
                for (let col = interactionRange.s.c; col <= interactionRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });

                    if (row === 0) {
                        interactionWorksheet[cellAddress].s = {
                            fill: { fgColor: { rgb: "FFFFFF" } }, 
                            font: { bold: true, color: { rgb: "000000" } }
                        };
                    } else {
                        // Set styles for alternate rows
                        if (row % 2 === 0) {
                            interactionWorksheet[cellAddress].s = {
                                fill: { fgColor: { rgb: "D9D9D9" } },
                            };
                        } else {
                            interactionWorksheet[cellAddress].s = {
                                fill: { fgColor: { rgb: "FFFFFF" } },
                            };
                        }
                    }
                }
            }
            // Append the interaction sheet to the workbook
            XLSX.utils.book_append_sheet(workbook, interactionWorksheet, 'Interactions');
        }

        // Export the workbook
        XLSX.writeFile(workbook, `interactions_export_${currentDate}.xlsx`);
    };

    ////////////
    // Relevant interaction detail data below:
    ////////////
    
    const mergeInteractionData = () => {
        return interactionData.map(interaction => {
            // Mapping over assistance_types and creating a comma-separated list of names
            const assistanceNames = interaction.assistance_types
                ? interaction.assistance_types.map(a => a.name).join(', ')
                : null;
        
            // Mapping over classification_types to create a comma-separated list of names
            const classificationNames = interaction.classification_types
                ? interaction.classification_types.map(c => c.name).join(', ')
                : null;

            // Ensure lat and long are null if the reported values are 0,0
            const latitude = Number(interaction.latitude) === 0 ? null : interaction.latitude;
            const longitude = Number(interaction.longitude) === 0 ? null : interaction.longitude;

            // Format the date to mm/dd/yyyy
            const formattedDate = interaction.interaction_date
            ? new Date(interaction.interaction_date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })
            : null;
        
            return {
                interaction_date: formattedDate,
                staff_name: interaction.staff_name || null,
                business_legal_name: interaction.business_legal_name || null,
                business_trade_name: interaction.business_trade_name || null,
                business_ubi: interaction.business_ubi || null,
                assistance_types: assistanceNames,
                classification_types: classificationNames,
                gross_revenue: interaction.gross_revenue || null,
                people_employed: interaction.people_employed || null,
                interaction_type: interaction.interaction_type?.display_name || null,
                latitude: latitude,
                longitude: longitude,
                contact_type: interaction.contact_type?.display_name || null,
                first_name: interaction.first_name || null,
                last_name: interaction.last_name || null,
                email: interaction.email || null,
                phone: interaction.phone || null,
                note: interaction.note || null,                
            };
        });
    };
    
    // Now you can call both functions to get the data
    const interactionDetails = mergeInteractionData();

    const interactionHeaders = [
        { label: 'Interaction Date', key: 'interaction_date' },
        { label: 'Interaction Staff Name', key: 'staff_name' },
        { label: 'Interaction Business Legal Name', key: 'business_legal_name' },
        { label: 'Interaction Business Trade Name', key: 'business_trade_name' },
        { label: 'Interaction Business UBI', key: 'business_ubi' },
        { label: 'Interaction Assistance', key: 'assistance_types' },
        { label: 'Interaction Business Classification', key: 'classification_types' },
        { label: 'Interaction Gross Revenue', key: 'gross_revenue' },
        { label: 'Interaction People Employed', key: 'people_employed' },
        { label: 'Interaction Type', key: 'interaction_type' },
        { label: 'Interaction Latitude', key: 'latitude' },
        { label: 'Interaction Longitude', key: 'longitude' },
        { label: 'Interaction Contact Type', key: 'contact_type' },
        { label: 'Interaction First Name', key: 'first_name' },
        { label: 'Interaction Last Name', key: 'last_name' },
        { label: 'Interaction Email', key: 'email' },
        { label: 'Interaction Phone', key: 'phone' },
        { label: 'Interaction Note', key: 'note' },
    ];

    return ( 
        <span className="flex relative">
            <button
                id="interaction-download"
                onClick={handleDetailDownload}
                className="w-36 px-4 mr-4 text-button-blue font-bold text-sm bg-white border-2 border-solid border-button-blue rounded-md focus:outline-none focus:border-blue-500 hover:border-color-cai-blue h-[36px] appearance-none text-center"
            > 
                <p className="">Download</p>
                <img src={downloadSvg} alt="download icon" className="absolute top-[11px] left-4" />
            </button>
        </span>
    );
}

export default InteractionDownload;