import { useState, ChangeEvent, useContext, forwardRef, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect';
import DatePicker from 'react-datepicker';
import calendarIcon from '../../assets/calendar.svg';
import validator from 'validator';
import Select from 'react-select';
import { customStyles, customStylesXL } from '../../utils/customFormStyles';
import FormErrorModal from '../../components/Modals/FormErrorModal';
import { getTypeOptions } from '../../utils/formOptions';
import { Loader } from '@googlemaps/js-api-loader';
import SurveyModal from '../../components/Interactions/SurveyModal';
import AdoDataContext from '../../context/AdoDataContext';
import TypesContext from '../../context/TypesContext';
import InteractionDataContext from '../../context/InteractionDataContext';
import { InteractionData, LocationType, PartnerData } from '../../services/types';
import { useOrganizationContext } from '../../context/OrganizationContext';
import { calculateFiscalYear, formatPhoneNumber, sanitizeString, validateInteractionDate } from '../../utils/helpers';
import BackButtonHeader from '../../components/Navigation/BackButtonHeader';

declare global {
    interface Window {
        google: any;
    }
}

interface CustomCalendarProps {
    value?: string | null; // Assuming value is a string (formatted date) or null
    onClick?: () => void; // onClick handler for the button
}

function EditInteraction() {
    const { partnerData } = useContext(AdoDataContext);
    const { partnerName, partnerId } = useOrganizationContext();
    const ado = partnerName;
    const { typesData } = useContext(TypesContext);
    const { initiatives } = useContext(InitiativeDataContext);
    const { interactions, fetchInteractions, setInteractionsYear } = useContext(InteractionDataContext);

    const [partner, setPartner] = useState<PartnerData>();
    const [initiativeList, setInitiativeList] = useState<string[]>([]);
    const [initiative, setInitiative] = useState("");
    const [initiativeId, setInitiativeId] = useState<number | null>(null);
    const [staff, setStaff] = useState("");
    const [interactionType, setInteractionType] = useState("");
    const [assistanceType, setAssistanceType] = useState<string[]>([]);
    const [interactionDate, setInteractionDate] = useState<Date | null>(null);
    const [originalInteractionDate, setOriginalInteractionDate] = useState<Date | null>(null);
    const [note, setNote] = useState("");
    const [business, setBusiness] = useState("");
    const [businessLegal, setBusinessLegal] = useState("");
    const [ubi, setUbi] = useState("");
    const [hasUBI, setHasUBI] = useState(true);
    const [busClass, setBusClass] = useState<string[]>([]);
    const [employee, setEmployee] = useState("");
    const [revenue, setRevenue] = useState("");
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [contactType, setContactType] = useState("");
    const [hasContact, setHasContact] = useState(true);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showSurveyModal, setShowSurveyModal] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [formErrorMsg, setFormErrorMsg] = useState('');

    const currentFisYear = calculateFiscalYear('');

    const autocompleteRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const { id } = useParams();

    // Create select options for initiatives and industries
    const [interactionTypeOptions, setInteractionTypeOptions] = useState([]);
    const [contactTypeOptions, setContactTypeOptions] = useState([]);
    const [classificationTypeOptions, setClassificationTypeOptions] = useState([]);
    const [assistanceTypeOptions, setAssistanceTypeOptions] = useState([]);

    const initiativeOptions = initiativeList.map((initiativeOption) => ({
        value: initiativeOption,
        label: initiativeOption,
    }));

    // Set partnerId logic based on initiative or ADO
    useEffect(() => {
        if (ado) {
            const partner = partnerData.find(partner => sanitizeString(partner.name) === sanitizeString(ado));
            if (partner) {
                setPartner(partner);
            }
        }
    }, [initiative, ado, partnerData, initiatives]);

    const currentInitiatives = initiatives.filter(initiative => sanitizeString(initiative.partner_name) === sanitizeString(ado));

    // Ensure currentInitiatives is an array and handle potential undefined names
    const initiativeNames: string[] = (currentInitiatives || [])
    .map(initiative => initiative.name)
    .filter(name => typeof name === 'string' && name.trim() !== '')
    .sort((a, b) => a.localeCompare(b));

    // Handler for navigating back to Interactions page
    const navigateBack = () => {
        fetchInteractions();
        navigate(-1);
    };

    useDeepCompareEffect(() => {
        const interaction = interactions.find(interaction => interaction.id === Number(id));

        if (interaction) {
            const initiative = initiatives.find(initiative => initiative.id === interaction.initiative_id);
    
            if (initiative) {
                setInitiative(initiative.name)
                setInitiativeId(initiative.id);
            }
        };

        setInitiativeList(initiativeNames);
    }, []);

    useDeepCompareEffect(() => {

        setInitiativeList(initiativeNames);

        const fetchCurrentInteraction = async () => {

            if (!partner) {
                console.log('Partner not set, skipping fetch.');
                return;
            }

            const interaction = interactions.find(interaction => interaction.id === Number(id));
            let currInitiative;

            if (interaction) {
                currInitiative = initiatives.find(initiative => initiative.id === interaction.initiative_id);
            }

            if (!currInitiative) {
                setFormErrorMsg(`Current initiative ID for ${ado} not found.`);
                setShowModal(true);
                return;
            }
            
            const partnerId = partner.id;
            const initId = currInitiative.id;

            try {
                const response = await fetch(`${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getinteractionbyid?partnerId=${partnerId}&initiativeId=${initId}&id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
        
                const responseData = await response.json();
        
                if (!response.ok) {
                    throw new Error(responseData.error || 'Failed to fetch target');
                }
        
                console.log('Data successfully fetched from backend:', responseData);
                
                // Grab first interaction in returned data array
                const data: InteractionData = responseData.data[0];


                // Update select options and ensure inactive/legacy types are added to options
                setInteractionTypeOptions(getTypeOptions(typesData, 'interaction_types', data.interaction_type?.id.toString()));

                setContactTypeOptions(getTypeOptions(typesData, 'contact_types', data.contact_type?.id.toString()));

                setClassificationTypeOptions(getTypeOptions(typesData, 'classification_types', data.classification_types.map((ct: any) => ct.id.toString())));
                
                setAssistanceTypeOptions(getTypeOptions(typesData, 'assistance_types', data.assistance_types.map((at: any) => at.id.toString())));


                // Ensure inactive/legacy types are added to current selection                
                const updatedInteractionTypeOptions = getTypeOptions(typesData, 'interaction_types', data.interaction_type?.id.toString());

                const updatedContactTypeOptions = getTypeOptions(typesData, 'contact_types', data.contact_type?.id.toString());

                const updatedClassificationTypeOptions = getTypeOptions(typesData, 'classification_types', data.classification_types.map((ct: any) => ct.id.toString()));

                const updatedAssistanceTypeOptions = getTypeOptions(typesData, 'assistance_types', data.assistance_types.map((at: any) => at.id.toString()));


                // Map selected values to their corresponding keys
                const parsedDate = new Date(data.interaction_date);
                // Convert the UTC date string to a Date object directly
                const interactionDate = new Date(parsedDate.getUTCFullYear(), parsedDate.getUTCMonth(), parsedDate.getUTCDate());

                const mappedBusClass = data.classification_types.map((type: LocationType) =>
                    updatedClassificationTypeOptions.find((bc: any) => bc.value === type.id)?.value || ''
                );
                
                const mappedAssistanceType = data.assistance_types.map((type: LocationType) =>
                    updatedAssistanceTypeOptions.find((at: any) => at.value === type.id)?.value || ''
                );
                
                const mappedContactType = data.contact_type
                    ? updatedContactTypeOptions.find((option: { value: number; label: string }) => option.value === data.contact_type.id)?.value || ''
                    : '';
                
                const mappedInteractionType = data.interaction_type
                    ? updatedInteractionTypeOptions.find((option: { value: number; label: string }) => option.value === data.interaction_type.id)?.value || ''
                    : '';
                
                // Update the state with the fetched data
                setInitiativeId(data.initiative_id);
                setStaff(data.staff_name || '');
                setInteractionType(mappedInteractionType || '');
                setAssistanceType(mappedAssistanceType || []);
                setInteractionDate(interactionDate || '');
                setOriginalInteractionDate(interactionDate || '');
                setNote(data.note || '');
                setBusiness(data.business_trade_name || '');
                setBusinessLegal(data.business_legal_name || '');
                setUbi(data.business_ubi || '');
                setBusClass(mappedBusClass || []);
                setEmployee(data.people_employed || '');
                setRevenue(data.gross_revenue || '');
                setAddress(data.address || '');
                setLatitude(data.latitude || '');
                setLongitude(data.longitude || ''); 
                setFirst(data.first_name || '');
                setLast(data.last_name || '');
                setPhone(data.phone || '');
                setEmail(data.email || '');
                setContactType(mappedContactType || '');
                setHasContact(data.contact_available || false);
                setHasUBI(data.ubi_available || false);
            } catch (error) {
                // Log the caught error to Sentry
                Sentry.captureException(error);
                console.error('Error fetching data from backend:', error);
            }
        } 

        fetchCurrentInteraction();
    }, [partner]);

    // load script for google maps autocomplete widget and set address state to selected location
    useEffect(() => {
        
        const loader = new Loader({
            apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
            version: 'weekly',
            libraries: ['places'], // Include 'places' library for geocoding and places autocomplete
        });

        loader.load().then(() => {
            if (autocompleteRef.current && window.google && window.google.maps) {
                const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, {
                    types: ['geocode'], // Specify 'geocode' to get all possible addresses
                    componentRestrictions: { country: 'us' } // Restrict results to a specific country if needed
                });

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        // setSelectedPlace(place);
                        setAddress(place.formatted_address);

                        const lat = place.geometry.location.lat();
                        const lng = place.geometry.location.lng();

                        setLatitude(lat.toString());
                        setLongitude(lng.toString());

                        console.log('Latitude:', lat);
                        console.log('Longitude:', lng);
                    }
                });
            }
        });

    }, []);

    const handleSubmit = () => {
        setShowSurveyModal(true);
    };

    // Custom calendar reference component for React Datepicker see docs: (https://reactdatepicker.com/#example-custom-input)
    const CustomCalendar = forwardRef<HTMLButtonElement, CustomCalendarProps>(({ value, onClick }, ref) => (
        <button className={`w-40 pl-4 pr-4 py-2 h-10 text-left border border-solid ${isSubmitted && !value ? 'border-red-500' : 'border-tableGray'} rounded-md focus:outline-none focus:border-blue-500 appearance-none`} onClick={onClick} ref={ref}>
        {value || 'Select Date'}
        </button>
    ));

    // Change handlers for form fields
    ////////
    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) => {  
        setter(event.target.value);
    };

    const handleUbiToggle = () => setHasUBI(prevState => !prevState);
    const handleContactToggle = () => setHasContact(prevState => !prevState);

    const handleInteractionDateChange = (date: Date | null) => {
        setInteractionDate(date);
    }

    const handleInitiativeSelectChange = (selectedOption: any) => {
        const selectedInitiative = selectedOption ? selectedOption.value : '';
        setInitiative(selectedInitiative);
    
        // Find the initiative object that matches both the selected name and the partner id
        const selectedInitiativeObject = initiatives.find(initiative => 
            initiative.name === selectedInitiative && 
            Number(initiative.partner_id) === partnerId
        );
    
        // If found, update the initiative_id in newInteraction
        if (selectedInitiativeObject) {
            const initId = selectedInitiativeObject.id;
            setInitiativeId(initId);
        }
    };

    const handleTextAreaChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        setter(event.target.value);
    };

    // Change handler factory for react-select components
    const createSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string>>) => (selectedOption: any) => {
        setter(selectedOption ? selectedOption.value : '');
    };

    const createMultiSelectChangeHandler = (setter: React.Dispatch<React.SetStateAction<string[]>>) => (selectedOption: any) => {
        const selection = selectedOption ? selectedOption.map((option: any) => option.value) : [];
        setter(selection);
    };


    const validateForm = () => {
        
        const formattedPhone = formatPhoneNumber(phone);  // Format the phone number first

        const validations = [
            { 
                field: formattedPhone, 
                regex: /^(?:\+?1[-.\s]?)?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})(?:[\s,]*ext\.?\s*(\d+))?$/i, 
                message: 'Please enter a valid phone number.' 
            },
            { field: email.trim(), validator: validator.isEmail, message: 'Please enter a valid email address.' },
            { field: employee, validator: validator.isNumeric, message: 'Please enter a valid number for Number of Employees.' },
            { 
                field: ubi, 
                regex: /^[\d-]+$/,  // Supports numbers and dashes
                message: 'Please enter a valid UBI.' 
            },
            { field: revenue, validator: validator.isNumeric, message: 'Please enter a valid number for Gross Revenue.' },
            { field: latitude, validator: validator.isNumeric, message: 'Please enter a valid number for Latitude.' },
            { field: longitude, validator: validator.isNumeric, message: 'Please enter a valid number for Longitude.' },
        ];
    
        for (let { field, regex, validator, message } of validations) {
            if (field) {
                if (regex && typeof regex === 'object' && !regex.test(field)) {
                    setFormErrorMsg(message);
                    setShowModal(true);
                    return false;
                } else if (validator && typeof validator === 'function' && !validator(field.toString())) {
                    setFormErrorMsg(message);
                    setShowModal(true);
                    return false;
                }
            }
        }
        // form is valid
        return true; 
    };

    const handleSave = async () => {
        if (!validateForm()) {
            return;
        }

        const currentDate = new Date(); // Use the current date
        const { valid, message } = validateInteractionDate(currentDate, interactionDate, originalInteractionDate);

        if (!valid) {
            setFormErrorMsg(message);
            setShowModal(true);
            return;
        }

        setError(null);
        setIsSubmitted(true);
        
        let formattedDate;

        if (interactionDate) {
            const date = new Date(interactionDate);
        
            // Get the date in UTC format
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
            const day = String(date.getUTCDate()).padStart(2, '0');
            const hours = String(date.getUTCHours()).padStart(2, '0');
            const minutes = String(date.getUTCMinutes()).padStart(2, '0');
            const seconds = String(date.getUTCSeconds()).padStart(2, '0');
            const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');
        
            // Format the date as 2024-06-27T00:00:00.000Z
            formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
        }

        // Format phone number
        const formattedPhone = formatPhoneNumber(phone);

        const interactionTypeId = interactionType ? Number(interactionType) : null;
        const contactTypeId = contactType ? Number(contactType) : null;

        // Map selected keys to their corresponding values
        const formattedClassifications = busClass.map(id => ({ id }));
        const formattedAssistances = assistanceType.map(id => ({ id }));

        const updatedInteraction = {
            id: Number(id),
            interaction_date: formattedDate,
            staff_name: staff,
            interaction_type_id: interactionTypeId,
            business_trade_name: business,
            business_legal_name: businessLegal,
            people_employed: Number(employee),
            gross_revenue: Number(revenue),
            business_ubi: ubi,
            first_name: first,
            last_name: last,
            phone: formattedPhone,
            email: email,
            contact_type_id: contactTypeId,
            note: note,
            latitude: latitude,
            longitude: longitude,
            address: address,
            contact_available: hasContact,
            ubi_available: hasUBI,
            // industry_code: null,
            // sub_industry_code: null,
            assistance_types: formattedAssistances,
            classification_types: formattedClassifications
        };

        const payload = { "interactions": [updatedInteraction]};
        console.log('payload:', payload);
        
        if (interactionType && assistanceType && interactionDate && business && (!hasUBI || (hasUBI && ubi))) {
            try {
                const response = await fetch(`${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/updateinteraction?initiativeId=${initiativeId}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
        
                const responseData = await response.json();
        
                if (!response.ok) {
                    throw new Error(responseData.error || 'Failed to add target');
                }
        
                console.log('Data successfully sent to backend:', responseData);
                // Return to Interactions dashboard
                setInteractionsYear(currentFisYear.toString());
                navigateBack();
            } catch (error) {
                // Log the caught error to Sentry
                Sentry.captureException(error);
                console.error('Error sending data to backend:', error);
                setError('There was an issue saving the interaction. Please try again later.');
            }
        }
    };

    const handleCancel = () => {
        // Return to Interactions dashboard
        navigateBack();
    }
    

    return (
        <>
            <BackButtonHeader navigateBack={navigateBack} name='Interactions'/>
            <div className="m-2 p-4">
                {/* Edit Interaction Details */}
                <div className="mb-8 border border-solid border-titanium-white rounded-md">
                    <div className='bg-gotham-gray border-b border-solid border-titanium-white mb-5 rounded-t-md'>
                        <h2 className="text-white text-sm font-semibold py-3 ml-7">Edit Interaction Details</h2>
                    </div>
                    
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-1 ml-3'>
                        <div className="ff-container">
                            <label htmlFor="selectInitiative" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Initiative:
                            </label>
                            <div className="relative w-full">
                                <div className='flex flex-col w-full'>
                                    <Select
                                        id="selectInitiative"
                                        value={initiativeOptions.find(option => option.value === initiative)}
                                        onChange={handleInitiativeSelectChange}
                                        options={initiativeOptions}
                                        placeholder="Select Initiative"
                                        styles={customStylesXL(isSubmitted, initiative)}
                                        isClearable
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !initiative ? 'Initiative is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="ff-container">
                            <label htmlFor="inputStaffName" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Staff Name:
                            </label>
                            <div className="flex flex-col w-full">
                                <input
                                    id="inputStaffName"
                                    type="text"
                                    value={staff}
                                    required
                                    placeholder='Enter Staff Name'
                                    onChange={handleInputChange(setStaff)}
                                    className={`text-sm pl-4 w-full pr-4 py-2 border border-solid ${isSubmitted && !staff ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !staff ? 'Staff Name is required.' : ''}`}</p>
                            </div>
                        </div>
                        <div className="ff-container">
                            <label htmlFor="selectInteractionType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Interaction Type:
                            </label>
                            <div className="relative w-full">
                                <div className="flex flex-col w-full">
                                        <Select
                                            id="selectType"
                                            value={interactionTypeOptions.find((option: any) => option.value === interactionType)}
                                            onChange={createSelectChangeHandler(setInteractionType)}
                                            options={interactionTypeOptions}
                                            placeholder="Select Type"
                                            styles={customStyles(isSubmitted, interactionType)}
                                            isClearable
                                        />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && !interactionType ? 'Interaction Type is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="ff-container">
                            <label htmlFor="selectAssistanceType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Assistance Type:
                            </label>
                            <div className="relative w-full">
                                <div className="flex flex-col w-full">
                                    <Select
                                        id="selectType"
                                        value={assistanceTypeOptions.filter((option: any) => assistanceType.includes(option.value))}
                                        onChange={createMultiSelectChangeHandler(setAssistanceType)}
                                        options={assistanceTypeOptions}
                                        placeholder="Select Type"
                                        styles={customStylesXL(isSubmitted, assistanceType)}
                                        isClearable={false}
                                        isMulti
                                    />
                                    <p className="text-red-500 text-sm">{`${isSubmitted && assistanceType.length === 0 ? 'Assistance Type is required.' : ''}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-start my-4 mx-4 sm:mr-20">
                            <label htmlFor="inputInteractionDate" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Interaction Date:
                            </label>
                            <div className='flex flex-col w-full'>
                                <div className="relative w-full text-sm">
                                    <DatePicker
                                        selected={interactionDate}
                                        placeholderText='Choose Date'
                                        dateFormat='MM/dd/yyyy'
                                        onChange={handleInteractionDateChange}
                                        customInput={<CustomCalendar/>}
                                        required
                                    />
                                    <img src={calendarIcon} alt="calendar icon" className="absolute left-32 bottom-3 h-4 w-4 pointer-events-none" />
                                </div>
                                <p className="text-red-500 text-sm">{`${isSubmitted && !interactionDate ? 'Interaction Date is required.' : ''}`}</p>
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-start my-4 mx-4 sm:mr-20">
                            <label htmlFor="inputNote" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Note:
                            </label>
                            <div className='flex flex-col w-full'>
                                <textarea
                                    id="inputNote"
                                    value={note}
                                    placeholder='Add Note'
                                    onChange={handleTextAreaChange(setNote)}
                                    className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-28 appearance-none"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Edit Business Details */}
                <div className="mb-8 border border-solid border-titanium-white rounded-[4px]">
                    <div className='bg-gotham-gray border-b border-solid border-titanium-white mb-5 rounded-t-md'>
                        <h2 className="text-white text-sm font-semibold py-3 ml-7">Edit Business Details</h2>
                    </div>
                    
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-1 ml-3'>
                        
                        {/* Business Name */}
                        <div className="ff-container">
                            <label htmlFor="inputBusiness" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Business Name:
                            </label>
                            <div className='flex flex-col w-full'>
                                <input
                                    id="inputBusiness"
                                    type="text"
                                    value={business}
                                    placeholder='Enter Business Name'
                                    onChange={handleInputChange(setBusiness)}
                                    className={`text-sm pl-4 w-full pr-4 py-2 border border-solid ${isSubmitted && !business ? 'border-red-500' : 'border-tableGray'} rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && !business ? 'Business Name is required.' : ''}`}</p>
                            </div>
                        </div>
                        {/* Business Legal Name */}
                        <div className="ff-container">
                            <label htmlFor="inputBusinessLegal" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold text-balance w-48">
                                Business Legal Name:
                            </label>
                            <input
                                id="inputBusinessLegal"
                                type="text"
                                value={businessLegal}
                                placeholder='Enter Business Legal Name'
                                onChange={handleInputChange(setBusinessLegal)}
                                className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                            />
                        </div>
                        {/* No UBI available */}
                        <div className="ff-container h-14">
                            <label htmlFor="inputHasUbi" className="text-sm mb-2 sm:mb-0 sm:mr-6 font-semibold w-36">
                                No UBI Available:
                            </label>
                            <button
                                id="inputHasUbi"
                                type="button"
                                onClick={handleUbiToggle}
                                className={`w-14 h-7 rounded-full border border-gray-300 focus:outline-none relative overflow-hidden transition-all duration-300 ${!hasUBI ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                            >
                                <span
                                    className={`absolute left-0 top-0 bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${!hasUBI ? 'translate-x-7' : 'translate-x-0'}`}
                                />
                            </button>
                        </div>
                        {!hasUBI &&
                            <br></br>
                        }
                        {/* UBI */}
                        {hasUBI && 
                        <div className="ff-container">
                            <label htmlFor="inputUBI" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Business UBI:
                            </label>
                            <div className="flex flex-col w-full">
                                <p className="text-sm text-gotham-gray">
                                    Don't know the Business UBI? You can look it up manually <a href="https://secure.dor.wa.gov/gteunauth/_/" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">here</a>.
                                </p>
                                <input
                                    id="inputUBI"
                                    type="text"
                                    value={ubi}
                                    placeholder='Enter Business UBI'
                                    onChange={handleInputChange(setUbi)}
                                    className={`text-sm pl-4 w-full pr-4 py-2 border border-solid  rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none ${isSubmitted && hasUBI && !ubi ? 'border-red-500': 'border-tableGray'}`}
                                />
                                <p className="text-red-500 text-sm">{`${isSubmitted && hasUBI && !ubi ? 'UBI is required. Please Enter Business UBI.' : ''}`}</p>
                            </div>
                        </div>
                        }
                        <div className="ff-container">
                            <label htmlFor="selectBusinessClass" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Business Classification:
                            </label>
                            <div className="relative w-full">
                                <div className="flex flex-col w-full">
                                    <Select
                                        id="selectType"
                                        value={classificationTypeOptions.filter((option: any) => busClass.includes(option.value))}
                                        onChange={createMultiSelectChangeHandler(setBusClass)}
                                        options={classificationTypeOptions}
                                        placeholder="Select Type"
                                        styles={customStyles()}
                                        isClearable={false}
                                        isMulti
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ff-container">
                            <label htmlFor="inputEmployee" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                No. of Employees:
                            </label>
                            <input
                                id="inputEmployee"
                                type="text"
                                value={employee}
                                placeholder='Enter No. of Employees'
                                onChange={handleInputChange(setEmployee)}
                                className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                            />
                        </div>
                        <div className="ff-container">
                            <label htmlFor="inputRevenue" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Gross Revenue:
                            </label>
                            <input
                                id="inputRevenue"
                                type="text"
                                value={revenue}
                                placeholder='Enter Gross Revenue'
                                onChange={handleInputChange(setRevenue)}
                                className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                            />
                        </div>
                        <br></br>
                        <div className="ff-container">
                            <label htmlFor="inputAddress" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Address:
                            </label>
                            <div className='relative w-full flex flex-col'>
                                <input
                                    id="inputAddress"
                                    ref={autocompleteRef}
                                    type="text"
                                    value={address}
                                    placeholder='Enter Address'
                                    onChange={handleInputChange(setAddress)}
                                    className="w-full pr-20 pl-2 py-2 border border-solid border-tableGray text-sm rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none truncate"
                                />
                                {/* <p className="text-red-500 text-sm">{`${isSubmitted && !address && ((latitude === '0' || !latitude) || (longitude === '0' || !longitude))  ? 'Address and/or Latitude and Longitude is required. Please Choose to Enter an Address and/or Latitude and Longitude.' : ''}`}</p> */}
                            </div>
                        </div>
                        <br></br>
                        <div className="ff-container">
                            <label htmlFor="inputLatitude" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Latitude:
                            </label>
                            <input
                                id="inputLatitude"
                                type="text"
                                value={latitude}
                                placeholder='Enter Latitude'
                                onChange={handleInputChange(setLatitude)}
                                className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                            />
                        </div>
                        <div className="ff-container">
                            <label htmlFor="inputLongitude" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                Longitude:
                            </label>
                            <input
                                id="inputLongitude"
                                type="text"
                                value={longitude}
                                placeholder='Enter Longitude'
                                onChange={handleInputChange(setLongitude)}
                                className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                            />
                        </div>
                    </div>
                </div>

                {/* Edit Business Contact */}
                <div className="mb-8 border border-solid border-titanium-white rounded-[4px]">
                    <div className='flex justify-between items-center bg-gotham-gray border-b border-solid border-titanium-white mb-5 rounded-t-md'>
                        <h2 className="text-white text-sm font-semibold py-3 ml-7">Edit Business Contact</h2>
                        {/* Email survey link for BRE*/}
                        {Number(interactionType) === 1 && 
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            id="send-survey"
                            className="m-3 p-2 w-36 h-8 text-button-blue font-bold text-xs bg-white border border-solid border-white rounded-[4px] focus:outline-none focus:border-blue-500 appearance-none text-center"
                        >
                        Email Survey Link
                        </button>
                        }
                    </div>
                    <SurveyModal
                        isOpen={showSurveyModal}
                        onClose={() => setShowSurveyModal(false)}
                        surveyEmail={email}
                        surveyBusiness={business}
                        surveyBusinessLegal={businessLegal}
                        surveyUbi={ubi}
                        surveyName={first}
                        initiativeId={initiativeId}
                        interactionId={Number(id)}
                    />
                    
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-1 ml-3'>
                        {/* No Contact available */}
                        <div className="ff-container">
                            <label htmlFor="inputContact" className="text-sm mb-2 sm:mb-0 sm:mr-6 font-semibold w-36">
                                No Contact:
                            </label>
                            <button
                                id="inputContact"
                                type="button"
                                onClick={handleContactToggle}
                                className={`w-14 h-7 rounded-full border border-gray-300 focus:outline-none relative overflow-hidden transition-all duration-300 ${!hasContact ? 'bg-blue-500' : 'bg-gray-300'} focus:ring-2 focus:ring-blue-500 focus:outline-none`}
                            >
                                <span
                                    className={`absolute left-0 top-0 bg-white w-7 h-7 rounded-full transition-all duration-300 transform ${!hasContact ? 'translate-x-7' : 'translate-x-0'}`}
                                />
                            </button>
                        </div>
                        <br></br>
                        {/* First Name */}
                        {hasContact &&    
                            <div className="ff-container">
                                <label htmlFor="inputFirst" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                    First Name:
                                </label>
                                <input
                                    id="inputFirst"
                                    type="text"
                                    value={first}
                                    placeholder='Enter First Name'
                                    onChange={handleInputChange(setFirst)}
                                    className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                                />
                            </div>
                        }
                        
                        {/* Last Name */}
                        {hasContact && 
                            <div className="ff-container">
                                <label htmlFor="inputLast" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                    Last Name:
                                </label>
                                <input
                                    id="inputLast"
                                    type="text"
                                    value={last}
                                    placeholder='Enter Last Name'
                                    onChange={handleInputChange(setLast)}
                                    className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                                />
                            </div>
                        }
                        
                        {/* Contact Phone */} 
                        {hasContact &&
                            <div className="ff-container">
                                <label htmlFor="inputPhone" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                    Contact Phone:
                                </label>
                                <input
                                    id="inputPhone"
                                    type="text"
                                    value={phone}
                                    placeholder='Enter Contact Phone'
                                    onChange={handleInputChange(setPhone)}
                                    className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                                />
                            </div>
                        }
                        {/* Contact Email */}
                        {hasContact &&
                            <div className="ff-container">
                                <label htmlFor="inputEmail" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                    Contact Email:
                                </label>
                                <input
                                    id="inputEmail"
                                    type="text"
                                    value={email}
                                    placeholder='Enter Contact Email'
                                    onChange={handleInputChange(setEmail)}
                                    className="text-sm pl-4 w-full pr-4 py-2 border border-solid border-tableGray rounded-[4px] focus:outline-none focus:border-blue-500 h-10 appearance-none"
                                />
                            </div>
                        }
                        {/* Contact Type */}
                        {hasContact &&
                            <div className="ff-container">
                                <label htmlFor="selectContactType" className="text-sm mb-2 sm:mb-0 sm:mr-4 font-semibold w-48">
                                    Contact Type:
                                </label>
                                <div className="relative w-full">
                                    <Select
                                        id="selectType"
                                        value={contactTypeOptions.find((option: any) => option.value === contactType)}
                                        onChange={createSelectChangeHandler(setContactType)}
                                        options={contactTypeOptions}
                                        placeholder="Select Type"
                                        styles={customStyles()}
                                        isClearable
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <FormErrorModal
                        isOpen={showModal}
                        onClose={() => setShowModal(false)}
                        message={formErrorMsg}
                    />
                    <div className="flex items-center border-solid border-t border-titanium-white">
                        {/* Display the server error message if it exists */}
                        {error && (
                            <div className="text-red-500 text-sm mt-5 ml-7">
                                {error}
                            </div>
                        )}
                        <button
                            id="save-interaction"
                            onClick={handleSave}
                            className='ml-7 mt-5 mb-3 w-36 h-8 text-center text-white bg-gotham-gray border border-solid border-gotham-gray rounded-[4px] focus:border-blue-500 focus:outline-none hover:bg-blue-400 hover:border-blue-600 cursor-pointer'
                            // disabled={!isFormValid}
                        >
                            Save
                        </button>
                        <button
                            id="cancel-interaction"
                            onClick={handleCancel}
                            className="ml-7 mt-5 mb-3 w-36 h-8 text-center text-gotham-gray bg-white border border-solid border-gotham-gray rounded-[4px] focus:outline-none focus:border-red-400 hover:bg-red-400 hover:border-red-500 hover:text-white cursor-pointer"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditInteraction;