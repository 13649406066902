import { useContext, useEffect, useState } from "react";
import SearchInput from "../../components/Filters/SearchInput";
import ReportingDropdown from "../../components/Navigation/BreDropdown";
import FiscalYear from "../../components/Filters/FiscalYear";
import InteractionType from "../../components/Interactions/InteractionType";
import { calculateAvailableYears, calculateFiscalYear } from "../../utils/helpers";
import AssistanceType from "../../components/Interactions/AssistanceType";
import BulkActionBtn from "../../components/Interactions/BulkActionBtn";
import AddInteractionButton from "../../components/Interactions/AddInteractionButton";
import InteractionTable from "../../components/Interactions/InteractionTable";
import InteractionDataContext from "../../context/InteractionDataContext";
import useFilteredData from "../../hooks/useFilteredData";
import InitiativeDataContext from "../../context/InitiativeDataContext";
import InitiativeSort from "../../components/Interactions/InitiativeSort";
import Expand from "../../components/Navigation/Expand";
import { useIframeContext } from "../../context/IframeContext";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useFetchDefaultInitiative } from "../../hooks/useFetchDefaultInitiative";
import UploadInteractionsBtn from "../../components/Interactions/UploadInteractionsBtn";
import NoResultsMessage from "../../components/Filters/NoResultsMessage";
import HomeButton from "../../components/Navigation/HomeButton";
import InteractionDownload from "../../components/Interactions/InteractionDownload";


function Interactions() {
    const { interactions, loading: interactionsLoading, setInteractionsYear } = useContext(InteractionDataContext);
    const { initiatives } = useContext(InitiativeDataContext);
    const { isIframe } = useIframeContext();
    const { partnerName, partnerId } = useOrganizationContext();

    // Setting default initiative fields if present
    const { defaultInitiative } = useFetchDefaultInitiative(partnerId);
    const [defaultInitiativeData] = defaultInitiative; // Destructure the default initiative (first element)
    const defaultInitiativeId = defaultInitiativeData?.id;
    const defaultInitiativeName = defaultInitiativeData?.name;

    const [initiative, setInitiative] = useState('');
    const [ado, setAdo] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [interactionType, setInteractionType] = useState<string>('');
    const [assistanceType, setAssistanceType] = useState<string>('');
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [loading, setLoading] = useState(true);

    const availableYears = calculateAvailableYears();
    const currFisYear = calculateFiscalYear(year); 

    const filterState = { 
        initiative,
        ado,
        year,
        searchTerm,
        interactionType,
        assistanceType
    };
    
    // Save the filter state to localStorage when filters change
    const saveFiltersToLocalStorage = () => {
        localStorage.setItem('interactionFilters', JSON.stringify(filterState));
    };

    // Load filters from localStorage when the component mounts
    useEffect(() => {
        setLoading(true);
        const savedFilters = JSON.parse(localStorage.getItem('interactionFilters') || '{}');
        setInitiative(savedFilters.initiative || '');
        setInteractionsYear(savedFilters.year || currFisYear.toString());
        setYear(savedFilters.year || currFisYear.toString());
        setSearchTerm('');
        setInteractionType(savedFilters.interactionType || '');
        setAssistanceType(savedFilters.assistanceType || '');
        setAdo(partnerName);
        console.log('initial render');
        setLoading(false);
    }, []);

    const { filteredInteractions } = useFilteredData(undefined, undefined, initiatives, interactions, searchTerm, undefined, year, undefined, ado, interactionType, initiative, assistanceType);

    const filterByYear = (year: string) => {
        setYear(year);   
        setInteractionsYear(year);
        filterState.year = year;
        saveFiltersToLocalStorage();
    };

    const handleSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        filterState.searchTerm = searchTerm;
        saveFiltersToLocalStorage();
    };
    
    const filterByInteractionType = (interactionType: string) => {
        setInteractionType(interactionType);
        filterState.interactionType = interactionType;
        saveFiltersToLocalStorage();
    };
    
    const filterByInitiative = (initiative: string) => {
        setInitiative(initiative);
        filterState.initiative = initiative;
        saveFiltersToLocalStorage();
    };
    
    const filterByAssistanceType = (assistanceType: string) => {
        setAssistanceType(assistanceType);
        filterState.assistanceType = assistanceType;
        saveFiltersToLocalStorage();
    };

    const handleBulkAction = () => {
        // Perform bulk action on selected rows
        console.log("Performing bulk action on selected rows:", selectedRows);
    };

    const handleRemoveFilters = () => {
        setLoading(true);
        setInitiative('');
        setYear(currFisYear.toString());
        setInteractionsYear(currFisYear.toString());
        filterState.year = currFisYear.toString();
        saveFiltersToLocalStorage();
        setSearchTerm('');
        setInteractionType('');
        setAssistanceType('');
        setLoading(false);
    };

    return ( 
        <>
            <ReportingDropdown/>
            <div className="px-4 py-0 sm:py-4 md:py-4 lg:py-4">
                <div className="mx-2 my-2 relative">
                    <div className="flex flex-wrap items-end w-full justify-start">
                        <HomeButton/>
                        {isIframe && 
                        <div className="flex sm:mt-0 mt-1 mb-1 absolute md:relative top-0 right-0 md:top-auto md:right-auto md:ml-auto transform md:transform-none">
                            <Expand/>
                        </div>
                        }
                    </div>
                </div>
                <div className="mx-2 my-4 flex flex-wrap relative">
                    <div className="w-5/6 sm:w-auto">
                        <SearchInput onSearch={handleSearch} isLarge={true}/>
                    </div>
                    <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                    <InitiativeSort initiatives={initiatives} filterByInitiative={filterByInitiative} currInitiative={initiative} ado={ado}/>
                    <InteractionType filterByInteractionType={filterByInteractionType} currInteractionType={interactionType}/>
                    <AssistanceType filterByAssistanceType={filterByAssistanceType} currAssistanceType={assistanceType}/>
                </div>

                {interactionsLoading || loading ? (
                    <div className="flex justify-center items-center my-16">
                            <p className="text-md font-bold text-color-cai-dashboard mr-8">Loading Interactions</p>
                            <div className="loader"></div>
                    </div>
                ) : (
                    <div className="mx-2 my-4">
                        <div className="flex flex-wrap items-end w-full justify-start mb-2">
                            <AddInteractionButton/>
                            
                            <BulkActionBtn disabled={selectedRows.length === 0} handleBulkAction={handleBulkAction} selectedRows={selectedRows}/>
                            {defaultInitiativeData && (
                                <div
                                    className='flex items-center justify-center'
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <UploadInteractionsBtn id={defaultInitiativeId} initiative={defaultInitiativeName} initiativeData={defaultInitiativeData} isDefault={false} />
                                </div>
                            )}
                            {filteredInteractions.length > 0 && <InteractionDownload interactionData={filteredInteractions}/>}
                        </div>
                    
                        {year && filteredInteractions.length > 0 ? (
                            <InteractionTable 
                                data={filteredInteractions} 
                                handleSelectRows={setSelectedRows}
                            />
                        ) : (
                            <NoResultsMessage 
                                itemName="interactions"
                                hasFilters={true}
                                onRemoveFilters={handleRemoveFilters}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

export default Interactions;