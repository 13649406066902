import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ConfirmDeleteModal from '../../components/Modals/ConfirmDeleteModal';
import editIcon from '../../assets/edit.svg';
import { InteractionDataPayload, UploadInteractionData } from '../../services/types';
import { getTypeOptions } from '../../utils/formOptions';
import TypesContext from '../../context/TypesContext';
import InitiativeDataContext from '../../context/InitiativeDataContext';
import { formatPhoneNumber, validateInteractionDate } from '../../utils/helpers';
import Expand from '../../components/Navigation/Expand';
import { useIframeContext } from '../../context/IframeContext';
import UploadErrorSort from '../../components/Interactions/UploadErrorSort';
import FormErrorModal from '../../components/Modals/FormErrorModal';


function ValidateDataFrame() {
    const { typesData } = useContext(TypesContext);
    const { initiatives } = useContext(InitiativeDataContext);
    const { isIframe } = useIframeContext();
    const [data, setData] = useState<UploadInteractionData[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [allSelected, setAllSelected] = useState(false);
    const [hasMissingFields, setHasMissingFields] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState('');

    // Filter related state
    const [filteredData, setFilteredData] = useState(data);
    const [currentFilter, setCurrentFilter] = useState('all');
    
    const navigate = useNavigate();
    const location = useLocation();
    
    if (!location.state) {
        console.log('No state provided, returning to upload page');
    
        const [timeLeft, setTimeLeft] = useState(5);
    
        useEffect(() => {
            setFormErrorMsg(
                `Session Expired. Your session may have timed out, please re-upload your file to continue. Returning to the landing page in ${timeLeft} seconds.`
            );
            setShowErrorModal(true);

            // Set an interval to update the countdown every second
            const interval = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev > 1) {
                        setFormErrorMsg(
                            `Session Expired. Your session may have timed out, please re-upload your file to continue. Returning to the landing page in ${
                                prev - 1
                            } seconds.`
                        );
                        return prev - 1;
                    } else {
                        clearInterval(interval);
                        setShowErrorModal(false);
                        navigate('/'); // Navigate to the landing page
                        return 0;
                    }
                });
            }, 1000);
    
            // Cleanup the interval on unmount
            return () => clearInterval(interval);
        }, []);

        return (
            <FormErrorModal
                isOpen={showErrorModal}
                onClose={() => setShowErrorModal(false)}
                message={formErrorMsg}
            />
        );
    }

    const { file, id, initiative, initiativeId, from } = location.state;

    console.log('file:', file);
    console.log('initiative id:', initiativeId)
    const currentInitiative = initiatives.find(initiative => initiative.id === Number(initiativeId));
    const partnerId = Number(currentInitiative?.partner_id);

    const fileData = file
  ? { name: file.name || null, type: file.type || null, status: 'Pending', partner_id: partnerId || null, creation_date: file.lastModifiedDate || null }
  : { name: null, type: null, status: 'Pending', partner_id: partnerId || null, creation_date: null };

    const status = fileData.status;

    // Create select options for initiatives and industries
    const interactionTypeOptions = getTypeOptions(typesData, 'interaction_types', undefined);
    const contactTypeOptions = getTypeOptions(typesData, 'contact_types', undefined);
    const classificationTypeOptions = getTypeOptions(typesData, 'classification_types', undefined);
    const assistanceTypeOptions = getTypeOptions(typesData, 'assistance_types', undefined);

    useEffect(() => {
        filterByMissingFields(currentFilter); // Update filtered data when data or filter changes
    }, [data, currentFilter]);

    const filterByMissingFields = (filter: string) => {
        setCurrentFilter(filter);
    
        // Transform data once outside the filter function
        const mappedData = transformPayload(data);
    
        if (filter === 'missing') {
            // Filter based on whether the row has missing fields
            setFilteredData(data.filter((_, index) => validateRow(mappedData[index])));
        } else {
            // Show all data if not filtering by missing fields
            setFilteredData(data);
        }
    };

    // Check if a single row has missing fields
    const validateRow = (row: InteractionDataPayload) => {

        // Ensure row is defined and is an object
        if (!row || typeof row !== 'object') {
            return false;
        }
        // Check for mandatory fields
        const isMissingBusinessName = !row.business_trade_name;
        const isMissingInteractionType = !row.interaction_type_id;
        
        // Check for assistance_types being empty or not existing
        const isMissingAssistance = !row.assistance_types || row.assistance_types.length === 0;
        
        const isMissingDate = !row.interaction_date;

        // Check invalid date
        const currentDate = new Date(); // Use the current date
        const { valid } = validateInteractionDate(currentDate, new Date(row.interaction_date));

        // Check for business_ubi based on ubi_available
        const isMissingBusinessUbi = row.ubi_available && !row.business_ubi;

        // Return true if any required field is missing
        return isMissingBusinessName || isMissingInteractionType || isMissingAssistance || isMissingDate || !valid || isMissingBusinessUbi;
    };

    // Check if any row in the dataset has missing fields
    const checkMissingFields = (data: InteractionDataPayload[]) => data.some(validateRow);

    // Function to clean the upload data before sending it vercel temp db
    const cleanInteractionData = (data: UploadInteractionData[]) => {
        return data.map((row) => {
            return {
                ...row,
                // Convert people_employed and gross_revenue to integers
                people_employed: typeof row.people_employed === 'string'
                    ? parseInt(row.people_employed, 10)
                    : typeof row.people_employed === 'number'
                    ? row.people_employed
                    : null, // Handle cases where the value is not a string or number
                
                gross_revenue: typeof row.gross_revenue === 'string'
                    ? parseInt(row.gross_revenue.replace(/,/g, ''), 10)
                    : typeof row.gross_revenue === 'number'
                    ? row.gross_revenue
                    : null, // Handle cases where the value is not a string or number
            };
        });
    };

    useEffect(() => {
        if (location.state && location.state.data) {
            setData(location.state.data);

            const currPayload = transformPayload(location.state.data);

            const checkedData = checkMissingFields(currPayload);

            setHasMissingFields(checkedData);
        }
        console.log('data to validate:', location.state.data);
        console.log('file info:', file);
    }, [location.state.data]);


    function transformPayload(data: UploadInteractionData[]): InteractionDataPayload[] {
        return data.map(item => {
            // Validate and format the date
            let formattedDate: string = '';
            const parsedDate = new Date(item.date);
            if (!isNaN(parsedDate.getTime())) {
                formattedDate = parsedDate.toISOString().split('T')[0];
            } else {
                // Handle invalid date case
                formattedDate = ''; // or provide a default date, e.g., '1970-01-01'
            }

            // Format phone number only if it exists, otherwise return null
            const formattedPhone = item.phone ? formatPhoneNumber(item.phone) : null;

            // Removes all unwanted characters, leaving only numbers and dashes in
            const ubi = item.business_ubi?.replace(/[^\d-]/g, '') ?? '';
            
            // Trim incoming comma-separated data and make case insensitive
            const trimmedAssistances = item.assistance
            ? item.assistance
                .replace(/[\{\}]/g, '') // Remove curly braces
                .replace(/"/g, '') // Remove extra quotes
                .split(',')
                .map(v => v.trim().toLowerCase())
            : []; // Default to an empty array if undefined or null

            const trimmedClassifications = item.business_classification
            ? item.business_classification
                .replace(/[\{\}]/g, '') // Remove curly braces
                .replace(/"/g, '') // Remove extra quotes
                .split(',')
                .map(v => v.trim().toLowerCase())
            : []; // Default to an empty array if undefined or null


            // Map incoming assistance and classification data to type API definitions
            const assistanceIds = trimmedAssistances
                .map(name => {
                    const matchedOption = assistanceTypeOptions.find((option: any) => option.label.toLowerCase() === name);
                    return matchedOption ? matchedOption.value : null;
                })
                .filter(id => id !== null); // Remove any null values

            const classificationIds = trimmedClassifications
                .map(name => {
                    const matchedOption = classificationTypeOptions.find((option: any) => option.label.toLowerCase() === name);
                    return matchedOption ? matchedOption.value : null;
                })
                .filter(id => id !== null); // Remove any null values


            // Map selected keys to their corresponding values
            const formattedClassifications = classificationIds.map(id => ({ id }));
            const formattedAssistances = assistanceIds.map(id => ({ id }));
            
            const mappedContactType = item.contact_type
                ? contactTypeOptions.find((option: { value: number; label: string }) => option.label.trim().toLowerCase() === item.contact_type.trim().toLowerCase())?.value || ''
                : '';
            
            const mappedInteractionType = item.interaction_type
                ? interactionTypeOptions.find((option: { value: number; label: string }) => option.label.trim().toLowerCase() === item.interaction_type.trim().toLowerCase())?.value || ''
                : '';

            // Clean monetary values by removing any "$" or "," characters if present, and round to the nearest integer
            const cleanValue = (value: any) => {
                if (typeof value === 'string') {
                    return Math.round(Number(value.replace(/[$,]/g, '')) || 0);
                } else if (typeof value === 'number') {
                    return Math.round(value);
                } else {
                    return 0;
                }
            };

            // Validate and constrain latitude and longitude
            let latitude = Number(item.latitude);
            let longitude = Number(item.longitude);

            if (isNaN(latitude) || latitude < -90 || latitude > 90) {
                latitude = 0;
            }

            if (isNaN(longitude) || longitude < -180 || longitude > 180) {
                longitude = 0;
            }

            return {
                interaction_date: formattedDate,
                staff_name: item.staff_name || "",
                interaction_type_id: mappedInteractionType ? Number(mappedInteractionType) : null,
                business_trade_name: item.business_trade_name || "",
                business_legal_name: item.business_legal_name || "",
                people_employed: cleanValue(item.people_employed),
                gross_revenue: cleanValue(item.gross_revenue),
                business_ubi: ubi || "",
                first_name: item.first_name || "",
                last_name: item.last_name || "",
                phone: formattedPhone || "",
                email: item.email || "",
                contact_type_id: mappedContactType ? Number(mappedContactType) : null,
                note: item.note || "",
                latitude: latitude,
                longitude: longitude,
                address: item.address || "",
                contact_available: !!item.phone || !!item.email || !!item.first_name || !!item.last_name || !!mappedContactType,
                ubi_available: !!ubi,
                industry_code: null,
                sub_industry_code: null,
                assistance_types: formattedAssistances,
                classification_types: formattedClassifications
            };
        });
    }

    const payload = transformPayload(data);
    console.log('payload:', payload);

    const handleCheckboxChange = (id: number) => {
        const index = selectedRows.indexOf(id);
        let updatedSelectedRows: number[];
        if (index === -1) {
            updatedSelectedRows = [...selectedRows, id];
        } else {
            updatedSelectedRows = selectedRows.filter(rowId => rowId !== id);
        }
        setSelectedRows(updatedSelectedRows);
        setAllSelected(updatedSelectedRows.length === data.length);
    };

    const handleAllCheckboxChange = () => {
        if (allSelected) {
            setSelectedRows([]);
        } else {
            const allRows = data.map((_: UploadInteractionData, index: number) => index);
            setSelectedRows(allRows);
        }
        setAllSelected(!allSelected);
    };

    const handleEditClick = (id: number) => {
        navigate(`/upload-interactions/edit/${id}`, { state: { data, initiative, file: fileData, status, initiativeId, id } });
    };

    const handleDeleteClick = () => {
        setShowModal(true);
    }

    const handleConfirmDelete = () => {
        // Filter out rows that are selected
        const newData = data.filter((_, index) => !selectedRows.includes(index));
        setData(newData);
        setSelectedRows([]);
        setAllSelected(false); // Reset allSelected state
        const checkMissingFields = newData.some((row: UploadInteractionData) =>
            !row.business_trade_name || (row.ubi_available && !row.business_ubi) || !row.interaction_type ||
            !row.assistance || !row.date
        );
        setHasMissingFields(checkMissingFields);

        console.log('Deleted rows:', selectedRows);
        setShowModal(false);
    }

    console.log('data to upload:', data);

    const BATCH_SIZE = 150; // Batch size of 150 records

    // Function to send data in batches to a specific API endpoint
    const sendBatches = async (apiUrl: string, data: any[], fileData?: {
        name: any;
        type: any;
        status: string;
        partner_id: number | null;
        creation_date: any;
    }) => {
        for (let i = 0; i < data.length; i += BATCH_SIZE) {
            const batch = data.slice(i, i + BATCH_SIZE);

            // Prepare data for the current batch
            const payload = fileData ? { fileData, interactionData: batch } : { "interactions": batch };

            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                const responseData = await response.json();

                if (!response.ok) {
                    throw new Error(responseData.error || 'Failed to upload data');
                }

                console.log('Data successfully sent to backend:', responseData);
            } catch (error) {
                console.error('Error sending data to backend:', error);
            }
        }
    };

    const handleNext = async () => {

        setLoading(true);

        const currPayload = transformPayload(data);
    
        const checkedData = checkMissingFields(currPayload);
    
        setHasMissingFields(checkedData);
    
        // Set file status based on missing fields
        fileData.status = 'Complete';
    
        // Ensure data is populated correctly before uploading
        if (data.length === 0) {
            console.error('No data to upload');
            return;
        }
    
        // Clean upload data for expected db schema
        const cleanUploadData = cleanInteractionData(data);
    
        const uploadInteractionUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/uploadinteraction`;
        const addInteractionUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/addinteraction?partnerId=${partnerId}&initiativeId=${initiativeId}`;
    
        try {
            // Send data in batches to the uploadinteraction endpoint
            await sendBatches(uploadInteractionUrl, cleanUploadData, fileData);
    
            // Transform interactions for permanent DB
            const newInteractions = transformPayload(data);
            // const payload = { interactions: newInteractions };
    
            // Send data in batches to the addinteraction endpoint
            await sendBatches(addInteractionUrl, newInteractions);
        } catch (error) {
            console.error('Error sending data:', error);
            setFormErrorMsg('There was an issue saving the interactions. Please try again later.');
            setShowErrorModal(true);
            return;
        } finally {
            setLoading(false); // Set loading to false before navigating
            navigate('/upload-interactions/success', { state: { id, initiativeId } });
        }
    };

    const handleSave = async () => {

        // Set file status to pending if saving
        fileData.status = 'Pending';
    
        // Ensure data is populated correctly before uploading
        if (data.length === 0) {
            console.error('No data to upload');
            return;
        }

        console.log('file data:', fileData);
    
        // Clean upload data for expected DB schema
        const cleanUploadData = cleanInteractionData(data);
    
        const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/uploadinteraction`;
    
        const combinedData = {
            fileData,
            interactionData: cleanUploadData,
        };
    
        try {
            // Send combined data to backend
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(combinedData),
            });
                        
            const responseData = await response.json();
    
            if (!response.ok) {
                throw new Error(responseData.error || 'Failed to upload file and interactions');
            }
    
            console.log('Data successfully sent to backend:', responseData);
            navigate('/upload-interactions/draftsaved', { state: { initiativeId } });

        } catch (error) {
            console.error('Error sending data to backend:', error);
            setFormErrorMsg('There was an issue saving the interactions. Please try again later.');
            setShowErrorModal(true);
        }
    };

    const handleCancel = () => {
        // We need to handle the path validating uploads
        if (from === '/upload-history') {
            navigate('/upload-history', { state: { initiativeId } });
        } else {
            navigate('/upload-interactions', { state: { id, file: fileData, initiativeId, status } });
        }
    };

    return (
        <div className="p-4 m-2">
            <div className="mb-8 pb-2 border border-solid border-titanium-white rounded-lg w-full">
                <div className="flex items-center py-3 bg-gotham-gray border-b border-solid border-titanium-white rounded-t-md">
                    <h2 className="font-semibold text-xl ml-7 w-1/3 text-white">Upload Interactions</h2>
                    {isIframe && 
                        <div className="flex ml-auto mr-4 mb-4">
                            <Expand/>
                        </div>
                    }
                </div>
                {!loading ? 
                <div>
                    <span className='flex'>
                        <h3 className='sm:ml-16 ml-4 my-5 w-full font-semibold text-gotham-gray'>{`${fileData.status == 'Complete' ? 'Uploaded Interaction Data' : 'Validate Data'}`}</h3>
                        <button 
                            onClick={(e) => { e.stopPropagation(); handleDeleteClick() }} 
                            className={`mx-7 mt-5 mb-3 w-36 h-8 text-center text-color-cai-dashboard bg-white border border-solid border-color-cai-dashboard rounded-md focus:outline-none focus:border-red-400 hover:bg-red-400 hover:border-red-500 hover:text-white ${!selectedRows.length || fileData.status == 'Complete' ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-400 hover:border-blue-600'}`}>
                            Delete
                        </button>
                        <ConfirmDeleteModal
                            isOpen={showModal}
                            onClose={() => setShowModal(false)}
                            onConfirm={handleConfirmDelete}
                            message={`Are you sure you want to delete ${selectedRows.length} records(s)?`}
                        />
                    </span>
                    
                    <p className='ml-4 sm:ml-16 text-color-cai-red text-sm'>{`${fileData.status == 'Complete' ? 'View your completed upload records. List cannot be edited as the upload process is complete.' : 'Review your uploaded list for errors. Click "Edit" to fix issues. Resolve all errors to complete the upload process.'}`}</p>
                    <div className='ml-4 sm:ml-16 my-3'> 
                        <UploadErrorSort
                            filterByMissingFields={filterByMissingFields}
                            currentFilter={currentFilter}
                        />
                    </div>

                    <div className="overflow-x-auto border border-solid border-titanium-white sm:mx-16 mx-4 my-2 rounded-lg">
                        <table className="min-w-full">
                            <thead className="bg-spanish-gray text-white rounded-t-lg">
                                <tr>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">
                                        <input type="checkbox" onChange={handleAllCheckboxChange} checked={allSelected} />
                                    </th>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">Business Name</th>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">Business UBI</th>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">Interaction Type</th>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">Assistance Type</th>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">Interaction Date</th>
                                    <th className="border-b border-titanium-white text-xs font-bold px-4 py-2 text-left">Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((row: any, index: number) => {

                                    const mappedData = transformPayload(filteredData);

                                    // Check if the current row has missing fields
                                    const rowHasMissingFields = validateRow(mappedData[index]);

                                    const rowClass = rowHasMissingFields ? 'bg-[#FFC9C9] border-color-[#FFC9C9]' : '';

                                    const assistanceTypes = (row.assistance ? row.assistance.replace(/{|}/g, "").replace(/"/g, "").split(",") : []);

                                    const mappedAssistanceType = assistanceTypes.map((assistance: string) =>
                                        assistanceTypeOptions.find((bc: any) => bc.label === assistance)?.label || '');

                                    const mappedInteractionType = row.interaction_type
                                    ? interactionTypeOptions.find((option: { value: number; label: string }) => option.label.trim().toLowerCase() === row.interaction_type.trim().toLowerCase())?.label || ''
                                    : '';

                                    // Validate and format the date
                                    let formattedDate: string = '';
                                    const parsedDate = new Date(row.date);
                                    if (!isNaN(parsedDate.getTime())) {
                                        formattedDate = parsedDate.toISOString().split('T')[0];
                                    } else {
                                        // Handle invalid date case
                                        formattedDate = ''; // or provide a default date, e.g., '1970-01-01'
                                    }

                                    // Removes all unwanted characters, leaving only numbers and dashes in
                                    const businessUbi = row.business_ubi?.replace(/[^\d-]/g, '') ?? '';

                                    return (
                                        <tr key={index} className={`border-b border-solid border-titanium-white hover:bg-at-white hover:text-black hover:cursor-pointer last:border-none h-10 ${rowClass}`}>
                                            <td className="px-4 py-2 text-gotham-gray text-sm text-left">
                                                <input type="checkbox" onChange={() => handleCheckboxChange(index)} checked={selectedRows.includes(index)} />
                                            </td>
                                            <td className="px-4 py-2 text-gotham-gray text-sm text-left" onClick={(e) => { e.stopPropagation(); handleEditClick(index) }}>
                                                {row.business_trade_name || ''}
                                            </td>
                                            <td className="px-4 py-2 text-gotham-gray text-sm text-left" onClick={(e) => { e.stopPropagation(); handleEditClick(index) }}>
                                                {businessUbi || ''}
                                            </td>
                                            <td className="px-4 py-2 text-gotham-gray text-sm text-left" onClick={(e) => { e.stopPropagation(); handleEditClick(index) }}>
                                                {mappedInteractionType || ''}
                                            </td>
                                            <td className="px-4 py-2 text-gotham-gray text-sm text-left" onClick={(e) => { e.stopPropagation(); handleEditClick(index) }}>
                                                {mappedAssistanceType.length > 0 && (
                                                    mappedAssistanceType.map((type: any) => type).join(', ')
                                                )}
                                            </td>
                                            <td className="px-4 py-2 text-gotham-gray text-sm text-left" onClick={(e) => { e.stopPropagation(); handleEditClick(index) }}>
                                                {formattedDate}
                                            </td>
                                            <td className="flex w-24 px-4 py-2 text-gotham-gray justify-center items-center">
                                                <button onClick={(e) => { e.stopPropagation(); handleEditClick(index) }} className="mr-3 flex items-center">
                                                    <img className="border border-solid border-button-blue h-6 w-6 p-1 rounded-md" src={editIcon} alt="Edit" />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <FormErrorModal
                        isOpen={showErrorModal}
                        onClose={() => setShowErrorModal(false)}
                        message={formErrorMsg}
                    />
                    <div className="flex items-center justify-between border-t border-solid border-titanium-white">
                        {/* Left-aligned buttons */}
                        <div className="flex items-center">
                            <button
                                id="save-target"
                                onClick={handleNext}
                                className={`ml-8 mt-5 mb-3 w-24 sm:w-36 h-8 text-center text-white ${hasMissingFields || fileData.status == 'Complete'  ? 'bg-gray-300 cursor-not-allowed opacity-50' : 'bg-gotham-gray'} border border-solid border-gotham-gray rounded-md focus:outline-none ${!hasMissingFields && 'hover:bg-blue-400 hover:border-blue-600'}`}
                                disabled={hasMissingFields || fileData.status == 'Complete'}
                            >
                                Next
                            </button>
                            <button
                                id="cancel-target"
                                onClick={handleCancel}
                                className="ml-3 sm:ml-8 mt-5 mb-3 w-24 sm:w-36 h-8 text-center text-gotham-gray bg-white border border-solid border-gotham-gray rounded-md focus:outline-none focus:border-red-400 hover:bg-red-400 hover:border-red-500 hover:text-white"
                            >
                                Back
                            </button>
                        </div>

                        {/* Right-aligned Save Draft button */}
                        <button
                            id="save-draft"
                            onClick={handleSave}
                            className={`mr-8 mt-5 mb-3 w-24 sm:w-36 h-8 text-center text-gotham-gray ${fileData.status == 'Complete' ? 'bg-gray-300 cursor-not-allowed opacity-50' : 'bg-white'} border border-solid border-gotham-gray rounded-md focus:outline-none hover:bg-blue-400 hover:border-blue-600 hover:text-white ml-auto`}
                            disabled={fileData.status == 'Complete'}
                        >
                            Save Draft
                        </button>
                    </div>
                </div>
                : (
                <div className="loading-container">
                    <div className='flex flex-col items-center justify-center'>
                        <p className='text-gotham-gray text-md font-bold mb-2'>Uploading Interactions...</p>
                        <div className="loading-spinner"></div>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
}

export default ValidateDataFrame;