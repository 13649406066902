import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { AdminProviderProps, InitiativeData, ProjectDataContextType } from '../services/types';
import * as Sentry from '@sentry/react';
import { useOrganizationContext } from './OrganizationContext';

// Configure axios to retry failed requests
axiosRetry(axios, {
    retries: 3, // Number of retry attempts
    retryDelay: axiosRetry.exponentialDelay, // Exponential backoff delay
    retryCondition: (error) => {
        return axiosRetry.isNetworkOrIdempotentRequestError(error); // Retry on network or 5xx server errors
    },
});

const ProjectDataContext = createContext<ProjectDataContextType>({
    projects: [],
    fetchProjects: () => {},
});

export const ProjectDataProvider = ({ children }: AdminProviderProps) => {
    const [projects, setProjects] = useState<InitiativeData[]>([]);
    const { partnerId } = useOrganizationContext();
    const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getprojects`;

    const fetchProjects = async () => {
        try {
            const response = await axios.get(apiUrl, { params: { partnerId }});
            if (response.status === 200) {
                const data = response.data.data;
                setProjects(data);
            } else {
                // Add Sentry breadcrumbs for extra information
                Sentry.addBreadcrumb({
                    category: 'API',
                    message: 'Error fetching projects',
                    data: { apiUrl, response: response.data },
                });

                // Log the error to Sentry
                Sentry.captureMessage(`Error fetching Projects: ${response.data.message}`);
                console.error('Error fetching Projects:', response.data);
            }
        } catch (error) {
            // Specific error handling for Axios network errors
            if (axios.isAxiosError(error)) {
                // Check for network error or timeout
                if (error.code === 'ECONNABORTED') {
                    Sentry.captureMessage('Request Timeout: The request took too long to complete', 'warning');
                } else if (error.message.includes('Network Error')) {
                    Sentry.captureMessage('Network Error: Unable to reach the server', 'error');
                }

                // Log additional details like the URL and request payload
                Sentry.withScope(scope => {
                    scope.setContext('Axios Request', { url: apiUrl });
                    scope.setTag('error_code', error.code || 'UNKNOWN');
                    Sentry.captureException(error);
                });

                console.error('Error fetching outcomes:', error);
            } else {
                // Handle non-Axios errors (e.g., JavaScript errors)
                Sentry.captureException(error);
                console.error('Unexpected error fetching outcomes:', error);
            }
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <ProjectDataContext.Provider value={{ projects, fetchProjects }}>
            {children}
        </ProjectDataContext.Provider>
    );
};

export default ProjectDataContext;
