import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { AdminProviderProps, InteractionData, InteractionDataContextType } from '../services/types';
import * as Sentry from '@sentry/react';
import { useOrganizationContext } from './OrganizationContext';

// Configure axios to retry failed requests
axiosRetry(axios, {
    retries: 3, // Number of retry attempts
    retryDelay: axiosRetry.exponentialDelay, // Exponential backoff delay
    retryCondition: (error) => {
        return axiosRetry.isNetworkOrIdempotentRequestError(error); // Retry on network or 5xx server errors
    },
});

const InteractionDataContext = createContext<InteractionDataContextType>({
    interactions: [],
    fetchInteractions: () => {},
    loading: false,
    interactionsYear: '',
    setInteractionsYear: () => {},
});

export const InteractionDataProvider = ({ children }: AdminProviderProps) => {
    const [interactions, setInteractions] = useState<InteractionData[]>([]);
    const [loading, setLoading] = useState(false);
    const [interactionsYear, setInteractionsYear] = useState('');
    const { partnerId } = useOrganizationContext();
    const apiUrl = `${import.meta.env.VITE_COMMERCE_BACKEND_URL}/api/getinteractions`;

    const fetchInteractions = async () => {
        setLoading(true);

        if (partnerId === -1 || !partnerId) {
            // Don't fetch interactions if partnerId is -1
            console.log('Skipping interactions fetch for partnerId -1 (Admin)');
            setLoading(false);
            return;
        }

        if (!interactionsYear) {
            console.log('Skipping interactions fetch: interactionsYear is not set');
            setLoading(false);
            return;
        }

        // Determine if interactionsYear should be empty based on "all" value
        const yearParam = interactionsYear === 'all' ? '' : interactionsYear;

        try {
            const response = await axios.get(apiUrl, { params: { partnerId, interactionsYear: yearParam }});
            // const response = await axios.get(apiUrl, { params: { partnerId }});
            if (response.status === 200) {
                const data = response.data.data;
                setInteractions(data);
            } else {
                console.error('Error fetching interactions:', response.data);
                setLoading(false);
            }
        } catch (error) {
            // Specific error handling for Axios network errors
            if (axios.isAxiosError(error)) {
                // Check for network error or timeout
                if (error.code === 'ECONNABORTED') {
                    Sentry.captureMessage('Request Timeout: The request took too long to complete', 'warning');
                } else if (error.message.includes('Network Error')) {
                    Sentry.captureMessage('Network Error: Unable to reach the server', 'error');
                }

                // Log additional details like the URL and request payload
                Sentry.withScope(scope => {
                    scope.setContext('Axios Request', { url: apiUrl });
                    scope.setTag('error_code', error.code || 'UNKNOWN');
                    Sentry.captureException(error);
                });

                console.error('Error fetching outcomes:', error);
            } else {
                // Handle non-Axios errors (e.g., JavaScript errors)
                Sentry.captureException(error);
                console.error('Unexpected error fetching outcomes:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInteractions();
    }, [partnerId, interactionsYear]);

    // useEffect(() => {
    //     fetchInteractions();
    // }, [partnerId]);

    return (
        <InteractionDataContext.Provider value={{ interactions, fetchInteractions, loading, interactionsYear, setInteractionsYear }}>
            {children}
        </InteractionDataContext.Provider>
    );
};

export default InteractionDataContext;