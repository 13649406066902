import { useContext, useEffect, useState } from "react";
import { calculateAvailableYears, calculateFiscalYear, calculateInteractionTotals } from "../../utils/helpers";
import SearchInput from "../../components/Filters/SearchInput";
import ReportingDropdown from "../../components/Navigation/BreDropdown";
import SelectADO from "../../components/Filters/SelectADO";
import FiscalYear from "../../components/Filters/FiscalYear";
import OutcomesContext from "../../context/OutcomeDataContext";
import SummaryOutcomesTable from "../../components/Summary/SummaryOutcomeTable";
import InitiativeDataContext from "../../context/InitiativeDataContext";
import SummaryCard from "../../components/Summary/SummaryCard";
import useFilteredData from "../../hooks/useFilteredData";
import InteractionDataContext from "../../context/InteractionDataContext";
import completeSvg from '../../assets/circle-check-filled.svg';
import ActivitiesBySectorChart from "../../components/Summary/ActivitiesBySectorChart";
import InteractionsByCategoryChart from "../../components/Summary/InteractionsByCategoryChart";
import Expand from "../../components/Navigation/Expand";
import { useAdminContext } from "../../context/AdminContext";
import DownloadButton from "../../components/Summary/DownloadButton";
import { useIframeContext } from "../../context/IframeContext";
import TargetsDataContext from "../../context/TargetDataContext";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useFetchInteractionSummaryData } from "../../hooks/useFetchInteractionSummaryData";
import AdoDataContext from "../../context/AdoDataContext";
import InitiativeSort from "../../components/Interactions/InitiativeSort";
import NoResultsMessage from "../../components/Filters/NoResultsMessage";
import HomeButton from "../../components/Navigation/HomeButton";
import PrintButton from "../../components/Summary/PrintButton";
import QuarterSort from "../../components/Filters/QuarterSort";
import ProjectSummaryTable from "../../components/Summary/ProjectSummaryTable";

function Summary() {
    const { isAdmin } = useAdminContext();
    const { isIframe } = useIframeContext();
    const { partnerName } = useOrganizationContext();
    const { partnerData } = useContext(AdoDataContext);

    const { initiatives } = useContext(InitiativeDataContext);
    const { outcomes } = useContext(OutcomesContext);
    const { interactions, setInteractionsYear } = useContext(InteractionDataContext);
    const { targets } = useContext(TargetsDataContext);

    const [ado, setAdo] = useState<string>('');
    const [initiative, setInitiative] = useState('');
    const [partnerId, setPartnerId] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortType, setSortType] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [quarter, setQuarter] = useState<string>('');

    const { filteredOutcomes, totalTargets, filteredInteractions, filteredInitiatives } = useFilteredData(targets, outcomes, initiatives, interactions, searchTerm, sortType, year, quarter, ado, undefined, initiative);


    const availableYears = calculateAvailableYears();
    const currFisYear = calculateFiscalYear(year); 

    const { interactionSummary } = useFetchInteractionSummaryData(year, partnerId?.toString() || '', initiative);
    // total interactions for each category
    const totalInteractions = calculateInteractionTotals(interactionSummary);
    // total BRE surveyed interactions
    const surveyedInteractions = interactionSummary?.types.find(type => type.id === 1)?.surveyed_interactions || 0;

    useEffect(() => {
        setSortType('alphabetical');
        setYear(currFisYear.toString());
        setInteractionsYear(currFisYear.toString());
    }, []);

    // Effect to set partnerId whenever ado changes
    useEffect(() => {
        if (isAdmin) {
            const partner = partnerData.find(partner => partner.name === ado);
            if (partner) {
                setPartnerId(partner.id?.toString()); // Set partnerId based on ado
            } else {
                // Set partnerId based on partnerName if no partner is found
                if (partnerName === 'Admin') {
                    setPartnerId('-1'); // Set to Admin partnerId
                } else if (partnerName === 'CAIAdmin') {
                    setPartnerId('-2'); // Set to CAIAdmin partnerId
                } else {
                    setPartnerId(''); // Clear partnerId if no match found and not Admin or CAIAdmin
                }
            }
        } else {
            setAdo(partnerName);  // Set ado if not admin

            const partner = partnerData.find(partner => partner.name === partnerName);

            if (partner) {
                setPartnerId(partner.id?.toString()); // Set partnerId based on ado
            } else {
                setPartnerId('');
            }
        }
    }, [ado, partnerData, partnerName, isAdmin]);

    const filterByYear = (year: string) => {
        setYear(year);   
        setInteractionsYear(year);
    };
    const filterByQuarter = (quarter: string) => setQuarter(quarter);
    const filterByAdo = (partner_id: string) => setAdo(partner_id);
    const filterByInitiative = (initiative: string) => setInitiative(initiative);
    const handleSearch = (searchTerm: string) => setSearchTerm(searchTerm);

    const handleRemoveFilters = () => {
        setInitiative('');
        setAdo('');
        setYear(currFisYear.toString());
        setSearchTerm('');
    };

    const handlePrint = () => {
        window.print();
    };

    return ( 
        <>
            <ReportingDropdown/>
            <div className="m-2 p-2">
                <div className="hidden-on-print mx-2 my-2 relative flex flex-wrap items-end w-full justify-between">
                    <HomeButton/>
                    <PrintButton onClick={handlePrint}/>
                    {isIframe && 
                    <div className="flex sm:mt-0 mt-2 absolute top-0 right-0 mr-2">
                        <Expand/>
                    </div>
                    }
                </div>
                <div className="flex justify-between items-center">
                <p className="hidden-on-print text-gotham-gray text-sm font-bold ml-2 -mb-2">Select options to filter the results below:</p>
                </div>
                <div className="mx-2 my-4 flex flex-wrap print:hidden">
                    <FiscalYear filterByYear={filterByYear} availableYears={availableYears} currYear={year}/>
                    <QuarterSort filterByQuarter={filterByQuarter} currQuarter={quarter}/>
                    {(isAdmin) && <SelectADO currAdo={ado} filterByAdo={filterByAdo} isSummary={true}/>}
                    {ado && <InitiativeSort initiatives={initiatives} filterByInitiative={filterByInitiative} currInitiative={initiative} ado={ado}/>}
                </div>
                {/* Print view of selected filters */}
                <div className="border border-solid border-titanium-white rounded-md py-3 ml-2 mt-2 mb-4 px-1 hidden print:flex bg-gotham-gray">
                    <p className="text-white mx-2">Fiscal Year: <strong>{year}</strong></p>
                    {quarter && <p className="text-white mx-2">Quarter: <strong>{quarter}</strong></p>}
                    {isAdmin && ado && <p className="text-white mx-2">ADO: <strong>{ado}</strong></p>}
                    {ado && initiative && <p className="text-white mx-2">Initiative: <strong>{initiatives?.find(init => Number(init.id) === Number(initiative))?.name}</strong></p>}
                </div>
                <div className="border border-solid border-titanium-white rounded-md p-0 ml-2 mt-2 mb-4">
                {/* <div className=""> */}
                    <h2 className="p-3 font-semibold bg-gotham-gray text-white rounded-t-md">Interactions</h2>
                    <div className="flex flex-wrap customSummary:flex-nowrap gap-4 mx-2 my-4 justify-center">
                        <div className="flex flex-col gap-4 print:grid print:grid-cols-6 print:gap-2 print:justify-center">
                            <div className="flex gap-4 print:hidden">
                                <SummaryCard title="BRE" number={totalInteractions.bre} target={totalTargets.bre} otherInfo={`${surveyedInteractions} surveys sent`}/>
                                <SummaryCard title="Recruitment" number={totalInteractions.recruitment} target={totalTargets.recruitment} />
                            </div>
                            <div className="flex gap-4 print:hidden">
                                <SummaryCard title="Training" number={totalInteractions.training} target={totalTargets.training} />
                                <SummaryCard title="Startup Support" number={totalInteractions.startup} target={totalTargets.startup} />
                            </div>
                            <div className="flex gap-4 print:hidden">
                                <SummaryCard title="Export Assistance" number={totalInteractions.export} target={totalTargets.export} />
                                <div className="w-full max-w-[11.5rem] md:w-1/2 h-28 border border-solid border-titanium-white rounded-[3px] appearance-none">
                                    <h2 className="text-gotham-gray text-sm font-bold py-2 px-3 bg-at-white">Marketing Plan</h2>
                                    
                                    {isAdmin ? (
                                        totalTargets.marketing_plan_received / totalTargets.marketing_plan_required === 1 ? (
                                            <div className="px-3 flex items-center">
                                                <img src={completeSvg} alt="Complete" className="w-5 h-5 mr-2 mt-1" />
                                                <p className="text-gotham-gray text-sm font-semibold mt-1">{`Completed`}</p>
                                            </div>
                                        ) : (
                                            <p className="px-3 text-gotham-gray text-sm font-semibold text-wrap m-3 md:my-1 md:mx-0">
                                                {`${totalTargets.marketing_plan_received} of ${totalTargets.marketing_plan_required} have been provided`}
                                            </p>
                                        )
                                    ) : (
                                        totalTargets.marketing_plan_required && totalTargets.marketing_plan_received ? (
                                            <div className="px-3 flex items-center">
                                                <img src={completeSvg} alt="Complete" className="w-5 h-5 mr-2 mt-1" />
                                                <p className="text-gotham-gray text-sm font-semibold mt-1">{`Completed`}</p>
                                            </div>
                                        ) : (
                                            <p className="px-3 text-gotham-gray text-sm font-semibold text-wrap m-3 md:my-1 md:mx-0">{`Incomplete`}</p>
                                        )
                                    )}
                                </div>
                            </div>

                            {/* PRINT VIEW ONLY */}
                            <div className="hidden print:block">
                                <SummaryCard title="BRE" number={totalInteractions.bre} target={totalTargets.bre} />
                            </div>
                            <div className="hidden print:block">
                                <SummaryCard title="Recruitment" number={totalInteractions.recruitment} target={totalTargets.recruitment} />
                            </div>
                            <div className="hidden print:block">
                                <SummaryCard title="Training" number={totalInteractions.training} target={totalTargets.training} />
                            </div>
                            <div className="hidden print:block">
                                <SummaryCard title="Startup Support" number={totalInteractions.startup} target={totalTargets.startup} />
                            </div>
                            <div className="hidden print:block">
                                <SummaryCard title="Export Assistance" number={totalInteractions.export} target={totalTargets.export} />
                            </div>
                            <div className="hidden print:block">
                                <div className="w-full max-w-[11.5rem] md:w-1/2 h-28 border border-solid border-titanium-white rounded-[3px] appearance-none">
                                    <h2 className="text-gotham-gray text-sm font-bold py-2 px-3 bg-at-white">Marketing Plan</h2>
                                    
                                    {isAdmin ? (
                                        totalTargets.marketing_plan_received / totalTargets.marketing_plan_required === 1 ? (
                                            <div className="px-3 flex items-center">
                                                <img src={completeSvg} alt="Complete" className="w-5 h-5 mr-2 mt-1" />
                                                <p className="text-gotham-gray text-sm font-semibold mt-1">{`Completed`}</p>
                                            </div>
                                        ) : (
                                            <p className="px-3 text-gotham-gray text-sm font-semibold text-wrap m-3 md:my-1 md:mx-0">
                                                {`${totalTargets.marketing_plan_received} of ${totalTargets.marketing_plan_required} have been provided`}
                                            </p>
                                        )
                                    ) : (
                                        totalTargets.marketing_plan_required && totalTargets.marketing_plan_received ? (
                                            <div className="px-3 flex items-center">
                                                <img src={completeSvg} alt="Complete" className="w-5 h-5 mr-2 mt-1" />
                                                <p className="text-gotham-gray text-sm font-semibold mt-1">{`Completed`}</p>
                                            </div>
                                        ) : (
                                            <p className="px-3 text-gotham-gray text-sm font-semibold text-wrap m-3 md:my-1 md:mx-0">{`Incomplete`}</p>
                                        )
                                    )}
                                </div>
                            </div>    
                        </div>
                        <div className="flex flex-wrap xl:flex-nowrap gap-4 w-full justify-center customSummary:justify-start">
                            <InteractionsByCategoryChart interactionSummary={interactionSummary}/>
                            <ActivitiesBySectorChart interactionSummary={interactionSummary}/>
                        </div>
                    </div>
                </div>

                {/* { filteredInitiatives.length > 0 && filteredOutcomes.length > 0 && */}
                {isIframe && 
                    <div className="flex justify-end -mb-4 sm:hidden">
                        <Expand/>
                    </div>
                }

                
                <div className="ml-2 my-8 border border-solid border-color-titanium-white rounded-md">
                    <div className="flex flex-wrap items-center justify-between w-full py-3 font-semibold bg-gotham-gray text-white rounded-t-md">
                        <div className="flex items-center justify-between w-full sm:w-auto">
                            <h2 className="font-semibold order-1 mx-4">Project Outcomes</h2>
                            <div className="hidden-on-print order-3">
                            <DownloadButton initiativeData={filteredInitiatives} outcomeData={filteredOutcomes} interactionData={filteredInteractions}/>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 bg-at-white summary-outcomes-print">
                        <>
                            <div className="print:hidden">
                                <ProjectSummaryTable initiativeData={filteredInitiatives} ado={ado} printView={false}/>
                            </div>
                            {/* Print view showing all outcome data */}
                            <div className="hidden print:block">
                                <ProjectSummaryTable initiativeData={filteredInitiatives} ado={ado} printView={true}/>
                            </div>
                        </>
                    </div>
                </div>

                <div className="ml-2 my-8 border border-solid border-color-titanium-white rounded-md">
                    <div className="flex flex-wrap items-center justify-between w-full py-3 font-semibold bg-gotham-gray text-white rounded-t-md">
                        <div className="flex items-center justify-between w-full sm:w-auto">
                            <h2 className="font-semibold order-1 mx-4">Projects</h2>
                            {!isAdmin && 
                            <div className="hidden-on-print order-3">
                            <DownloadButton initiativeData={filteredInitiatives.filter(initiative => initiative.category === 'project')} outcomeData={filteredOutcomes} interactionData={filteredInteractions}/>
                            </div>
                            }
                        </div>

                        {(isAdmin) && (
                            <div className="hidden-on-print w-full sm:w-auto mt-2 sm:mt-0 mx-4 order-2">
                                <SearchInput onSearch={handleSearch} isLarge={false}/>
                            </div>
                        )}
                    </div>
                    <div className="p-4 bg-at-white summary-outcomes-print">
                    {filteredInitiatives.filter(initiative => 
                        initiative.category === 'project' && 
                        Array.isArray(initiative.outcomes) && 
                        initiative.outcomes.length > 0
                    ).length > 0 ?
                        <>
                            <div className="print:hidden">
                                <SummaryOutcomesTable initiativeData={filteredInitiatives.filter(initiative => initiative.category === 'project')} printView={false}/>
                            </div>
                            {/* Print view showing all outcome data */}
                            <div className="hidden print:block">
                                <SummaryOutcomesTable initiativeData={filteredInitiatives.filter(initiative => initiative.category === 'project')} printView={true}/>
                            </div>
                        </>
                        :
                        <NoResultsMessage 
                            itemName="project outcomes"
                            hasFilters={true}
                            onRemoveFilters={handleRemoveFilters}
                        />
                        }   
                    </div>
                </div>

                <div className="ml-2 my-8 border border-solid border-color-titanium-white rounded-md">
                    <div className="flex flex-wrap items-center justify-between w-full py-3 font-semibold bg-gotham-gray text-white rounded-t-md">
                        <div className="flex items-center justify-between w-full sm:w-auto">
                            <h2 className="font-semibold order-1 mx-4">Initiatives</h2>
                            {!isAdmin && 
                            <div className="hidden-on-print order-3">
                            <DownloadButton initiativeData={filteredInitiatives.filter(initiative => initiative.category === 'initiative')} outcomeData={filteredOutcomes} interactionData={filteredInteractions}/>
                            </div>
                            }
                        </div>

                        {(isAdmin) && (
                            <div className="hidden-on-print w-full sm:w-auto mt-2 sm:mt-0 mx-4 order-2">
                                <SearchInput onSearch={handleSearch} isLarge={false}/>
                            </div>
                        )}
                    </div>
                    <div className="p-4 bg-at-white summary-outcomes-print">
                    {filteredInitiatives.filter(initiative => 
                        initiative.category === 'initiative' && 
                        Array.isArray(initiative.outcomes) && 
                        initiative.outcomes.length > 0
                    ).length > 0 ?
                        <>
                            <div className="print:hidden">
                                <SummaryOutcomesTable initiativeData={filteredInitiatives.filter(initiative => initiative.category === 'initiative')} printView={false}/>
                            </div>
                            {/* Print view showing all outcome data */}
                            <div className="hidden print:block">
                                <SummaryOutcomesTable initiativeData={filteredInitiatives.filter(initiative => initiative.category === 'initiative')} printView={true}/>
                            </div>
                        </>
                        :
                        <NoResultsMessage 
                            itemName="initiative outcomes"
                            hasFilters={true}
                            onRemoveFilters={handleRemoveFilters}
                        />
                        }   
                    </div>
                </div>
                
                {/* } */}
            </div>
        </>
    );
}

export default Summary;